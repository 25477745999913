<template>
  <div>
    <h4>{{ title }}</h4>
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: 'Toast',
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
p {
  font-size: 12px;
  line-height: 1.5;
  margin-top: 5px;
}
</style>
