<template>
  <ModalBase :open="open" @close="$emit('close')">
    <div class="modal-body">
      <div class="wrapper">
        <div class="left">
          <div class="top" :style="topStyle">
            <h2>New Project?</h2>
            <p>If this is your first time here or doing a new project, let us know the size of your wall!</p>
            <div v-show="step === 1">
              <MeasurementUnitToggle :metric="unit === 'metric'" @change="unit = $event" />
              <div class="dimension-inputs">
                <DimensionInput prefix="WIDTH" :unit="unit" :value="width" @change="width = $event" />
                <DimensionInput prefix="HEIGHT" :unit="unit" :value="height" @change="height = $event" />
              </div>
            </div>
            <button class="setting btn btn-get-started" @click="nextStep">Get Started</button>
            <button v-show="step === 1" class="btn btn-cancel" @click="step = 0">Cancel</button>
          </div>
          <div v-show="step === 0" class="separator">OR</div>
          <div v-show="step === 0" class="bottom">
            <h2>Load Unfinished Work</h2>
            <p>Enter your design code and pick up where you left off.</p>
            <div class="input-wrapper">
              <input type="text" v-model="designCode" />
              <button class="btn btn-load-work ga-load" @click="loadDesign">Load</button>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="message">
            <h1>Welcome to {{ isB2B ? 'Plan Your Project Tool' : 'Layout Assistant' }} Beta!</h1>
            <p>Let your imagination run free and create a design that will suit your needs and space. This tool lets you experience your masterpiece digitally before purchasing.</p>
          </div>
        </div>
      </div>
    </div>
  </ModalBase>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import DimensionInput from '../components/DimensionInput.vue';
import MeasurementUnitToggle from '../components/MeasurementUnitToggle.vue';
import ModalBase from './ModalBase.vue';
import loadDesign from '../loadDesign';

export default {
  name: 'Init',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      designCode: '',
      step: 0,
      lastStep: 1,
      unit: 'metric',
      width: 150,
      height: 90,
    };
  },
  watch: {
    unit(newUnit) {
      if (newUnit === 'metric') {
        this.width *= 2.54;
        this.height *= 2.54;
      } else {
        this.width /= 2.54;
        this.height /= 2.54;
      }
      this.width = Math.round(this.width);
      this.height = Math.round(this.height);
    },
  },
  computed: {
    ...mapState([
      'isB2B',
    ]),
    topStyle() {
      if (this.step === 1) {
        return { height: '90%' };
      }
      return {};
    },
  },
  methods: {
    ...mapMutations([
      'setMeasurementUnit',
      'setInitialWidthAndHeight',
    ]),
    loadDesign() {
      loadDesign(this.designCode);
    },
    nextStep() {
      this.step++;
      if (this.step > this.lastStep) {
        this.setMeasurementUnit(this.unit);
        this.setInitialWidthAndHeight({ width: this.width, height: this.height });
        this.$emit('close');
      }
    },
  },
  components: {
    DimensionInput,
    MeasurementUnitToggle,
    ModalBase,
  },
};
</script>

<style scoped>
input {
  font-size: 12px;
}

.prefix {
    margin-left: 15px;
}

.btn {
  font-size: 12px;
}

.modal-body {
  padding: 0 !important;
}

.modal-body > .wrapper {
  display: flex;
  height: 70vh;
}

.left, .right {
  display: flex;
  width: 50%;
}

.left {
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 70px 0;
}

.left .top, .left .bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 40%;
  width: 60%;
}

.top > div {
  width: 80%;
  margin: 0 auto;
}

.top > div > .wrapper {
  margin: 30px 0;
}

.left .top h2, .left .bottom h2 {
  font-size: 22px;
  line-height: 1.4;
}

.left .top p {
  font-size: 12px;
  margin: 5px 0 0;
}

.left .bottom p {
  font-size: 12px;
  margin: 5px 0 2em;
}

.btn-get-started {
  background-color: #3EAF29;
  border-radius: 25px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 25px;
  text-transform: uppercase;
}

.setting {
  margin: 2em 0 1em;
}

.separator {
  color: #DADEE1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  width: 150px;
  margin-bottom: 3em;
  margin-top: 1.5em;
}

.input-wrapper {
  display: flex;
}

.input-wrapper input {
  border-radius: 25px 0 0 25px;
  padding: 10px 15px;
  width: 80%;
}

.btn-load-work {
  background-color: #3B4C55;
  color: white;
  border-radius: 0 25px 25px 0;
  font-weight: bold;
  padding: 10px 15px;
  text-transform: uppercase;
  width: 80px;
}

.right {
  align-items: flex-end;
  background-image: url('../assets/modals/planprojectintro.jpg');
  background-size: cover;
  border-radius: 0 10px 10px 0;
}

.right .message {
  background-color: white;
  border-radius: 8px;
  margin: 30px 25px 10px;
  padding: 30px;
  text-align: center;
}

.right .message h1 {
  font-size: 14px;
  line-height: 1.4;
  margin: 0 auto;
  margin-bottom: 10px;
  opacity: 0.75;
  width: 85%;
}

.right .message p {
  font-size: 10px;
  opacity: 0.75;
}

.btn-cancel {
  font-weight: bold;
  text-transform: uppercase;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation:portrait) {
  .modal-body > .wrapper {
    height: 42vh;
  }
}

@media only screen and (min-device-width: 1025px) {
  .left {
    padding: 65px 0;
  }

  .left .top, .left .bottom {
    height: auto;
  }

  .right {
    background-position: 50% 60%;
  }

  .separator {
    margin-bottom: 0; 
    margin-top: 1em;
  }

  .right .message {
    margin: 30px 58px 20px;
    padding: 30px 55px;
    text-align: center;
  }

  .left .top h2, .left .bottom h2 {
    font-size: 32px;
  }

  .left .top p, .left .bottom p{
    font-size: 14px;
  }

  .setting {
    margin: 2em 0 1em;
  }

  .top > div {
    margin: 0 auto;
    width: 70%;
  }

  .top > div > .wrapper {
    margin: 60px 0 30px;
  }

  .dimension-inputs {
    margin-bottom: 3em;
  }
}

@media only screen and (min-device-width: 1280px) {
  .left {
    padding: 35px 0 45px;
  }

  .left h2 {
    font-size: 24px;
  }

  .top > div > .wrapper {
    margin: 25px 0 30px;
  }

  .dimension-inputs {
    margin-bottom: 1em;
  }
}

@media only screen and (min-device-height: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
  .left {
    padding: 75px 0;
  }
}

@media only screen and (min-device-width: 1366px) {
  .top > div > .wrapper {
    margin: 45px 0 30px;
  }

  .dimension-inputs {
    margin-bottom: 3em;
  }
}

@media only screen and (min-device-width: 1367px) {
  .right {
    background-position: 50% 65%;
  }

  .modal-body > .wrapper {
    height: 75vh;
  }

  .container {
    width: 62%;
  }

  .left .top h2, .left .bottom h2 {
    font-size: 28px;
  }
}

@media only screen and (min-device-width: 1600px) {
  .modal-body > .wrapper {
    height: 65vh;
  }

  .right .message {
    margin: 30px 30px 20px;
    padding: 30px 42px;
  }
}

@media only screen and (min-device-width: 1920px) {
  .modal-body > .wrapper {
    max-height: 700px;
  }

  .container {
    width: 40%;
  }

  .left {
    padding: 70px 0;
  }
}

@media only screen and (min-device-width: 2100px) {
  .right .message {
    margin: 30px 80px 20px;
  }

  .container {
    width: 35%;
  }
}
</style>
