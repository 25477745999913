<template>
  <ModalBase title="Error" width="400px" :open="open" @close="$emit('close')">
    <div class="modal-body">
      {{ message }}
    </div>
  </ModalBase>
</template>

<script>
import ModalBase from './ModalBase.vue';

export default {
  name: 'Error',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  components: {
    ModalBase,
  },
};
</script>

<style scoped>
.modal-body {
  margin-bottom: 20px;
}
</style>
