<template>
  <ModalBase :title="title" width="750px" :open="open" @close="$emit('close')">
    <div class="modal-body">
      <div class="tabs">
        <ul>
          <li v-for="(content, $index) in content" :key="'tab' + $index"
            :class="selectedTabIndex === $index ? 'active' : ''" @click="selectedTabIndex = $index">
            {{ content.title }}
          </li>
        </ul>
      </div>
      <main>
        <div class="description">
          <p v-html="content[selectedTabIndex].description"></p>
          <p v-if="content.length > 1" class="buttons">
            <button class="btn" @click="prevTab"><img src="../assets/arrowLeft.svg" alt="Previous" /></button>
            <button class="btn" @click="nextTab"><img src="../assets/arrowRight.svg" alt="Next" /></button>
          </p>
        </div>
        <div class="image">
          <img v-if="content[selectedTabIndex].images.length === 1"
            :src="content[selectedTabIndex].images[0]" :alt="content[selectedTabIndex].title" />
          <Carousel v-if="content[selectedTabIndex].images.length > 1"
            :data="carouselData[selectedTabIndex]"
            :controls="carouselData[selectedTabIndex].length > 1"
            :indicators="carouselData[selectedTabIndex].length > 1" indicator-type="disc" />
        </div>
      </main>
    </div>
  </ModalBase>
</template>

<script>
import Carousel from '@chenfengyuan/vue-carousel';

import ModalBase from './ModalBase.vue';

export default {
  name: 'LearnMore',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    content: {
      type: Array,
      required: true,
      validator(value) {
        return value.every((v) =>
          typeof v.title === 'string' &&
          typeof v.description === 'string' &&
          Array.isArray(v.images) &&
          v.images.every((img) => typeof img === 'string')
        );
      },
    },
  },
  mounted() {
    // used to override colour of indicators in Carousel
    // set colour in style.css
    for (const indicator of document.getElementsByClassName('carousel__indicator')) {
      indicator.setAttribute('data-colour', true);
    }
  },
  data() {
    return {
      selectedTabIndex: 0,
    };
  },
  computed: {
    carouselData() {
      return this.content.map((item) => item.images).map((images, i) => images.map((img) => `<img src="${img}" alt="${this.content[i].title}" />`));
    },
  },
  watch: {
    open() {
      // Wait before resetting tab so that it does not reset while the close animation is running
      setTimeout(() => {
        this.selectedTabIndex = 0;
      }, 500);
    },
  },
  methods: {
    prevTab() {
      if (this.selectedTabIndex === 0) this.selectedTabIndex = this.content.length - 1;
      else this.selectedTabIndex--;
    },
    nextTab() {
      this.selectedTabIndex = (this.selectedTabIndex + 1) % this.content.length;
    },
  },
  components: {
    Carousel,
    ModalBase,
  },
};
</script>

<style scoped>
.container main .description p {
  font-size: 12px;
}

.container main > div {
  padding-bottom: 35px;
}

.container main .description p:nth-child(1) {
  margin-top: 5em;
}

.container main .description p:nth-child(2) {
  margin-bottom: 0;
}

.container main .description .buttons button {
  background: none;
  margin-bottom: 0;
}

.container main .description .buttons button img {
  width: 18px;
}

.container .tabs {
  margin-top: 1em;
}

.container .tabs ul {
  justify-content: flex-start;
}

.container .tabs ul li {
  margin-right: 32px;
}

.container .tabs ul li:last-child {
  margin-right: 0;
}
</style>
