<template>
  <ModalBase title="Deleting current design" width="400px" :open="open" @close="$emit('cancel')">
    <div class="modal-body">
      <p>Do you wish to discard the current design and start a new one?</p>
      <div class="modal-actions">
        <button class="btn" @click="$emit('cancel')">Cancel</button>
        <button class="btn danger" @click="$emit('ok')">Delete and Start New</button>
      </div>
    </div>
  </ModalBase>
</template>

<script>
import ModalBase from './ModalBase.vue';

export default {
  name: 'StartNew',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ModalBase,
  },
};
</script>
