<template>
  <footer ref="footer">
    <button :class="'btn btn-footer prev' + (activeStep === 0 ? ' disabled' : '')"
      :disabled="activeStep === 0" @click="change(activeStep - 1)">
      <img src="../assets/arrowLeft.svg" alt="Previous" />
      Prev
    </button>
    <nav v-show="isNavOpen">
      <div v-for="(step, $index) in steps" :key="'step' + $index"
        :class="'nav-item' + (activeStep === $index ? ' active' : '') + (totalItemCount === 0 ? ' disabled' : '')"
        @click="change($index)">
        <span>
          <strong>{{ step.title }}</strong> ({{ $index + 1 }} / {{ steps.length }})
        </span>
        <span>{{ step.subtitle }}</span>
      </div>
    </nav>
    <div v-show="!isNavOpen" class="nav-item" @click="isNavOpen = true">
      <span>
        <strong>{{ steps[activeStep].title }}</strong> {{ stepOutOf }}
      </span>
      <span>{{ steps[activeStep].subtitle }}</span>
    </div>
    <button v-show="activeStep < steps.length - 1" :class="'btn btn-footer next' + (totalItemCount === 0 ? ' disabled' : '')"
      :disabled="totalItemCount === 0" @click="change(activeStep + 1)">
      Next
      <img v-show="totalItemCount === 0" style="margin-left: 5px;" src="../assets/arrowRight.svg" alt="Next" />
      <img v-show="totalItemCount > 0" style="margin-left: 5px;" src="../assets/arrowRightWhite.svg" alt="Next" />
    </button>
    <a v-if="!isB2B && activeStep === steps.length - 1" href="https://www.nanoleaf.me" target="_blank" ref="shopLink">
      <button class="btn btn-footer next">
        Shop <img style="margin-left: 5px; width: 16px;" src="../assets/shop.svg" alt="Shop" />
      </button>
    </a>
    <button v-if="isB2B && activeStep === steps.length - 1" class="btn btn-footer next disabled" disabled>
      Done
    </button>
  </footer>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { getCountryCode, getShopCountryCode } from '../geolocation';

export default {
  name: 'Footer',
  data() {
    return {
      isNavOpen: false,
    };
  },
  mounted() {
    document.addEventListener('click', (e) => {
      if (!e.composedPath().includes(this.$refs.footer)) {
        this.isNavOpen = false;
      }    
    });
  },
  computed: {
    ...mapGetters([
      'activeModel',
      'stepOutOf',
      'totalItemCount',
    ]),
    ...mapState([
      'activeStep',
      'steps',
      'isB2B',
    ]),
  },
  watch: {
    activeStep(step) {
      if (step === this.steps.length - 1 && !this.isB2B) {
        this.setShopLink();
      }
    },
  },
  methods: {
    ...mapActions([
      'setStep',
    ]),
    change(step) {
      this.isNavOpen = false;
      if (this.activeStep === 0) {
        // need to confirm with Wall that layout is valid
        this.$root.$emit('set-step', step);
      } else {
        this.setStep(step);
      }
    },
    async setShopLink() {
      const countryCode = await getCountryCode();
      const shopCountryCode = getShopCountryCode(countryCode);
      const url = new URL(`https://nanoleaf.me/en-${shopCountryCode}/products`);
      if (this.activeModel === 'NL22') {
        url.searchParams.set('product', 'light-panels');
      } else if (this.activeModel === 'NL29') {
        url.searchParams.set('product', 'canvas');
      } else if (this.activeModel === 'NL42') {
        url.searchParams.set('product', 'shapes');
      }
      url.searchParams.set('type', 'all');
      this.$refs.shopLink.setAttribute('href', url.toString());
    },
  },
};
</script>

<style scoped>
footer {
  box-shadow: 0px 0px 30px -10px rgb(0 0 0 / 50%);
  height: 30px;
  position: fixed;
  bottom: 25px;
  left: 50%;
  width: 575px;
  margin-left: -325px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
}

.btn-footer {
  background-color: white;
  font-weight: bold;
  padding: 20px 60px;
  text-transform: uppercase;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.btn-footer.prev:hover {
  background-color: #3EAF29;
  color: white;
}

.btn-footer.next:hover {
  background-color: #34A51F;
}

.btn-footer.prev {
  border-radius: 8px 0 0 8px;
}

.btn-footer.next {
  background-color: #3EAF29;
  border-radius: 0 8px 8px 0;
  color: white;
}

.btn-footer img {
  width: 20px;
}

.btn-footer.disabled {
  background-color: #F1F2F2 !important;
  color: #DADEE1 !important;
  cursor: default;
}

.nav-item {
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: space-around;
  padding: 10px;
  font-size: 16px;
}

.nav-item:hover {
  background: #F1F2F2;
}

.nav-item.disabled {
  cursor: default;
}

nav {
  flex: 1;
  margin-bottom: 100px;
}

nav .nav-item {
  justify-content: space-between;
}

nav .nav-item:first-child {
  border-radius: 5px 5px 0 0;
}

nav .nav-item:last-child {
  border-radius: 0 0 5px 5px;
}

nav .nav-item.active {
  background-color: #3EAF29;
  color: white;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  nav {
    margin-bottom: 124px;
  }

  .nav-item {
    background-color: white;
    padding: 20px 20px;
    font-size: 18px;
    justify-content: flex-start;
  }

  .nav-item span:nth-child(1) {
    width: 35%;
  }

  nav .nav-item:nth-child(1) {
    padding-top: 20px;
  }

  nav .nav-item:nth-child(4) {
    padding-bottom: 20px;
  }

  footer {
    bottom: 75px;
    width: 680px;
  }

  .btn-footer {
    background-color: #F1F2F2;
    font-size: 18px;
  }

  .btn-footer img {
    width: 12px;
  }

  .btn-footer:nth-child(1) img {
    padding-right: 5px;
  }

  .btn-footer.next {
    color: white;
  }

  .btn-footer.next .img {
    padding-left: 5px;
  }

  nav .nav-item {
    justify-content: flex-start;
  }

  nav .nav-item span:nth-child(1){
    width: 35%;
  }
}

@media only screen and (max-device-width: 1024px) {
  nav .nav-item span:nth-child(1) {
    width: 38%;
  }

  footer {
    bottom: 65px;
    margin-left: -275px;
    width: 585px;
  }

  .nav-item {
    background-color: white;
    font-size: 16px;
    justify-content: flex-start;
    padding: 16px 20px;
  }

  .btn-footer {
    background-color: #F1F2F2;
    font-size: 16px;
    padding: 16px 50px;
  }
}

@media only screen and (min-device-width: 1367px) {
  footer {
    bottom: 70px;
    width: 680px;
  }

  nav {
    margin-bottom: 135px;
  }

  .nav-item {
    background-color: white;
    font-size: 18px;
    justify-content: flex-start;
    padding: 20px 20px;
  }

  nav .nav-item {
    justify-content: flex-start;
  }

  .nav-item span:nth-child(1) {
    width: 40%;
  }

  nav .nav-item:nth-child(1) {
    padding-top: 20px;
  }

  nav .nav-item:nth-child(4) {
    padding-bottom: 20px;
  }

  .btn {
    font-size: 18px;
  }

  .btn-footer {
    background: #f1f2f2;
    padding: 20px 60px;
  }

  .btn-footer img {
    width: 14px;
  }

  .btn-footer:nth-child(1) img {
    padding-right: 5px;
  }

  .btn-footer.next {
    color: white;
  }
}
</style>
