import lightpanelsinfo1 from '../assets/modals/NL22/lightpanelsinfo1.jpg';
import lightpanelsinfo2 from '../assets/modals/NL22/lightpanelsinfo2.jpg';
import lightpanelsinfo3 from '../assets/modals/NL22/lightpanelsinfo3.jpg';
import lightpanelsinfo4 from '../assets/modals/NL22/lightpanelsinfo4.jpg';
import lightpanelsinfo5 from '../assets/modals/NL22/lightpanelsinfo5.jpg';

import canvasinfo1 from '../assets/modals/NL29/canvasinfo1.jpg';
import canvasinfo2 from '../assets/modals/NL29/canvasinfo2.jpg';
import canvasinfo3 from '../assets/modals/NL29/canvasinfo3.jpg';
import canvasinfo5 from '../assets/modals/NL29/canvasinfo5.jpg';
import canvasinfo6 from '../assets/modals/NL29/canvasinfo6.jpg';

import shapesinfo1 from '../assets/modals/NL42/shapesinfo1.jpg';
import shapesinfo2 from '../assets/modals/NL42/shapesinfo2.jpg';
import shapesinfo3 from '../assets/modals/NL42/shapesinfo3.jpg';
import shapesinfo4 from '../assets/modals/NL42/shapesinfo4.jpg';
import shapesinfo5 from '../assets/modals/NL42/shapesinfo5.jpg';
import shapesinfo6 from '../assets/modals/NL42/shapesinfo6.jpg';

export default {
  NL22: [
    {
      title: 'Rhythm Mode',
      description: 'The Rhythm Module reacts to all your favourite songs in real-time. It senses audio and transforms it into colour &amp; light for a stunning audiovisual display.',
      images: [lightpanelsinfo2],
    },
    {
      title: 'Set the Mood',
      description: 'You choose - energizing shades of purple for a workout energy boost or gentle warm white to read your favourite book before bedtime. The right ambience, guaranteed.',
      images: [lightpanelsinfo4],
    },
    {
      title: 'Screen Mirror',
      description: 'Watch your on-screen activity reflected onto your lights in real time with Screen Mirror. Use Match mode for next-level movie nights and gaming sessions.',
      images: [lightpanelsinfo5],
    },
    {
      title: 'Nanoleaf Integrations',
      description: 'Discover the convenience and wonder of controlling your lights with any of these supported integrations! We also support connections to popular smart home platforms, local network services, and popular gaming platforms. Now that’s smarter by design.',
      images: [lightpanelsinfo1],
    },
    {
      title: 'Scheduled Scenes',
      description: 'Get your day off to a great start and wake up naturally. Try the Sunrise as a Schedule when you want to wake up feeling refreshed.',
      images: [lightpanelsinfo3],
    },
  ],
  NL29: [
    {
      title: 'Rhythm Mode',
      description: 'Transforms your favourite songs into dancing symphonies of colour and light. Try the Pop Rocks Rhythm Scene and watch your lights move to the beat.',
      images: [canvasinfo2],
    },
    {
      title: 'Set the Mood',
      description: 'You choose - energizing shades of purple for a workout energy boost or gentle warm white to read your favourite book before bedtime. The right ambience, guaranteed.',
      images: [canvasinfo3],
    },
    {
      title: 'Screen Mirror',
      description: 'The colours from your screen are reflected on your panels for an immersive experience. Use Match mode for next-level movie nights and gaming sessions.',
      images: [canvasinfo6],
    },
    {
      title: 'Nanoelaf Integrations',
      description: 'Discover the convenience and wonder of controlling your lights with any of these supported integrations! We also support connections to popular smart home platforms, local network services, and popular gaming platforms. Now that’s smarter by design.',
      images: [canvasinfo5],
    },
    {
      title: 'Reacts to Touch',
      description: 'Watch the panels respond dynamically to your touch, control the lights, or even play interactive games. Try the Highlight Motion for a stunning ripple effect.',
      images: [canvasinfo1],
    },
  ],
  NL42: [
    {
      title: 'Combine Shapes',
      description: 'Create beautiful symmetry in any room, or get completely abstract - the choice is yours. With Connect+ technology in all Nanoleaf Shapes products, you can combine different shapes into a stunning light mosaic.',
      images: [shapesinfo1],
    },
    {
      title: 'Set the Mood',
      description: 'You choose - energizing shades of purple for a workout energy boost or gentle warm white to read your favourite book before bedtime. The right ambience, guaranteed.',
      images: [shapesinfo3],
    },
    {
      title: 'Rhythm Mode',
      description: 'Transforms your favourite songs into dancing symphonies of colour and light. Try the Pop Rocks Rhythm Scene and watch your lights move to the beat.',
      images: [shapesinfo5],
    },
    {
      title: 'Screen Mirror',
      description: 'The colours from your screen are reflected on your panels for an immersive experience. Use Match mode for next-level movie nights and gaming sessions.',
      images: [shapesinfo6],
    },
    {
      title: 'Nanoleaf Integrations',
      description: 'Discover the convenience and wonder of controlling your lights with any of these supported integrations! We also support connections to popular smart home platforms, local network services, and popular gaming platforms. Now that’s smarter by design.',
      images: [shapesinfo4],
    },
    {
      title: 'Reacts to Touch',
      description: 'Watch light panels respond dynamically to your touch, control lights, or even play interactive games. Try the Highlight Motion for a stunning ripple effect.',
      images: [shapesinfo2],
    },
  ],
};
