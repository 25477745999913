<template>
  <div class="final-wrapper">
    <div class="row header">
      <h2 class="title">{{ isB2B ? 'Contact Sales' : 'Shopping List' }}</h2>
      <div class="top">
        <button class="btn btn-download" :disabled="downloadingPdf" @click="downloadPdf">
          <img src="../assets/download.svg" alt="Download" />
          <span v-show="downloadingPdf">Downloading...</span>
          <span v-show="!downloadingPdf">Download Shopping List</span>
        </button>
        <Toggle v-if="isB2B" :leftActive="showingDetails" left="Details" right="Design" @change="showingDetails = $event" />
      </div>
    </div>
    <div class="row" style="margin-bottom: 200px;">
      <div v-if="isB2B" class="column" ref="contactSales">
        <ContactSales />
      </div>
      <div v-show="!isB2B || !showingDetails" class="column wall" ref="wall">
        <div class="toggle-wrapper" ref="toggleWrapper">
          <Toggle :leftActive="lightsOn" left="on" right="off" @change="lightsOn = $event" />
        </div>
      </div>
      <div v-show="!isB2B || showingDetails" class="column" ref="summary">
        <Summary />
      </div>
    </div>
    <Error :open="pdfError" message="Sorry, there was an error generating the PDF." @close="pdfError = false" />
    <Pdf />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import ContactSales from './ContactSales.vue';
import Error from '../modals/Error.vue';
import Pdf from './Pdf.vue';
import Summary from './Summary.vue';
import Toggle from './Toggle.vue';

export default {
  name: 'Final',
  data() {
    return {
      showingDetails: true,
      lightsOn: true,
      palettes: [
        ['#EA729F', '#FFB2BA', '#FFDCD3', '#FFF4A8', '#FCED4B', '#FECB4C', '#FF993D', '#FC765F', '#FF2741', '#D5143E'],
        ['#F0B0FB', '#D15EB8', '#5F4B9D', '#9390E5', '#C0ECFF', '#9AC9FF', '#4B7BE2', '#70CECC', '#B0F2B4', '#E8FFE1'],
        ['#DEF8B3', '#4ADABD', '#706EC4', '#60A1E2', '#BCE1F5', '#FFECF1', '#FDCB75', '#FF7F69', '#F72D55', '#E074AE'],
        ['#FC6E16', '#FA8D26', '#FFBB59', '#FCEA88', '#FEDC1A', '#6ACB52', '#AAF676', '#A4CFFF', '#77B5FE', '#303EBA'],
      ],
      paletteIndex: 0,
      downloadingPdf: false,
      pdfError: false,
    };
  },
  watch: {
    activeStep(step) {
      if (step === 3) {
        this.addWallClone();
      }
    },
    lightsOn(isOn) {
      if (isOn) {
        this.paletteIndex = (this.paletteIndex + 1) % this.palettes.length;
      }
      this.changePanelBackgrounds(isOn);
    },
    async showingDetails(value) {
      if (!value) {
        await this.$nextTick();
        this.addWallClone();
      }
    },
  },
  computed: {
    ...mapState([
      'isB2B',
      'activeStep',
      'wallWidthPx',
      'wallHeightPx',
      'designCode',
      'pdfReady',
    ]),
  },
  methods: {
    sanitizeWall(wall) {
      wall.setAttribute('id', 'wall-clone');
      wall.style.cssText = `background: none; margin: 0; height: ${this.wallHeightPx}px; width: ${this.wallWidthPx}px; transform-origin: left top;`;
      for (const drr of wall.getElementsByClassName('drr')) {
        drr.style.cursor = 'default';
      }
      return wall;
    },
    getRandomColourFromPalette() {
      const palette = this.palettes[this.paletteIndex];
      const index = Math.floor(Math.random() * palette.length);
      return palette[index];
    },
    changePanelBackgrounds(isOn) {
      const bgs = this.$refs.wall.getElementsByClassName('bg');
      for (const bg of bgs) {
        bg.style.fill = isOn ? this.getRandomColourFromPalette() : '#DADEE1';
      }
    },
    addWallClone() {
      const { contactSales, summary, toggleWrapper, wall } = this.$refs;
      // remove old wall
      if (wall.childNodes[1]) {
        wall.childNodes[1].remove();
      }

      const { clientHeight: otherColumnHeight } = this.isB2B ? contactSales : summary

      const wallCloneContainer = document.createElement('div');
      wallCloneContainer.style.cssText = `border: 1px solid #DADEE1; border-radius: 10px;`;
      wallCloneContainer.appendChild(this.sanitizeWall(document.getElementById('wall').cloneNode(true)));
      wall.appendChild(wallCloneContainer);

      // scale wall
      const { clientWidth: wallColumnWidth } = wall;
      const scale = this.wallWidthPx >= this.wallHeightPx ? wallColumnWidth / this.wallWidthPx : otherColumnHeight / this.wallHeightPx;
      document.getElementById('wall-clone').style.transform = `scale(${scale})`;

      // centre wall container
      wallCloneContainer.style.height = `${this.wallHeightPx * scale}px`;
      // - 2px translateY for border
      if (this.wallWidthPx >= this.wallHeightPx) {
        // need to recalculate otherColumnHeight because wall was appended after last calculation
        const { clientHeight: otherColumnHeight } = this.isB2B ? contactSales : summary
        const { clientHeight: toggleHeight } = toggleWrapper;
        wallCloneContainer.style.transform = `translateY(${0.5 * (otherColumnHeight - wallCloneContainer.clientHeight - toggleHeight - 2)}px)`;
      } else {
        wallCloneContainer.style.width = `${this.wallWidthPx * scale}px`;
        wallCloneContainer.style.transform = `translate(${0.5 * (wallColumnWidth - wallCloneContainer.clientWidth)}px, -2px)`;
      }

      this.changePanelBackgrounds(this.lightsOn);
    },
    downloadPdf() {
      async function waitUntilReady() {
        if (this.pdfReady) {
          const designUrl = new URL(location.href);
          designUrl.searchParams.set('design', this.designCode);
          designUrl.searchParams.set('pdf', 'true');

          const serverUrl = new URL(process.env.VUE_APP_PDF_URL);
          serverUrl.searchParams.set('selector', '#pdf-download');
          serverUrl.searchParams.set('url', designUrl.toString());

          try {
            const res = await fetch(serverUrl.toString());
            if (res.ok) {
              const blob = await res.blob();
              const url = URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.setAttribute('download', 'your_nanoleaf_design.pdf');
              link.setAttribute('href', url);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(url);
              this.downloadingPdf = false;
            } else {
              this.pdfError = true;
              this.downloadingPdf = false;
            }
          } catch {
            this.pdfError = true;
            this.downloadingPdf = false;
          }
        } else {
          setTimeout(waitUntilReady.bind(this), 100);
        }
      }
      this.downloadingPdf = true;
      waitUntilReady.call(this);
    },
  },
  components: {
    ContactSales,
    Error,
    Pdf,
    Summary,
    Toggle,
  },
};
</script>

<style scoped>
.final-wrapper {
  box-sizing: border-box;
  overflow: auto;
  padding: 5% 10%;
  height: calc(100vh - 65px);
  width: calc(100vw - 200px);
}

.title {
  text-align: center;
}

.row {
  display: flex;
}

.row.header {
  align-items: center;
  flex-direction: column;
}

.top-left {
  box-sizing: border-box;
  margin: 0 auto;
  text-align: center;
  width: 50%;
}

.top {
  display: flex;
  align-items: center;
  flex-direction: column;

}

table {
  font-size: 12px;
}

.btn {
  text-transform: uppercase;
}

.btn-download {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

.btn-download img {
  margin-right: 8px;
  width: 14px;
}

.column {
  background-color: white;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex; /* needed for both columns to have same height */
  flex-direction: column;
  margin: 20px;
  overflow: auto;
  padding: 40px;
  width: 50%;
}

.wall {
  overflow: hidden;
  padding: 0;
}

.toggle-wrapper {
  margin: 0 auto;
  width: 30%;
}

@media only screen and (min-device-width: 1025px) and (orientation:landscape) {
  .top-left h2 {
    font-size: 32px;
  }

  .btn-download {
    font-size: 16px;
    margin-top: 5px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .row.header {
    margin-bottom: 1em;
  }

  .final-wrapper {
    padding: 5% 2% 5%;
  }

  .toggle-wrapper {
    width: 43%;
  }
}

@media only screen and (min-device-width: 1367px) {
  .top-left h2 {
    font-size: 24px;
  }

  .final-wrapper {
    padding: 6% 5% 5%;
  }

  .toggle-wrapper {
    width: 43%;
  }
}

@media only screen and (min-device-width: 1680px) {
  .final-wrapper {
    padding: 4% 10% 5%;
  }

  .toggle-wrapper {
    width: 150px;
  }

  .top-left h2 {
    font-size: 32px;
  }

  .btn-download {
    font-size: 14px;
    margin-top: 5px;
  }
}
</style>
