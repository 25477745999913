<template>
  <div>
    <VueDragResizeRotate :w="width" :h="width" :x="x" :y="y" :resizable="false" :rotatable="false" :scale="scale"
      @dblclick.native="noteOpen = true" @drag="({ x, y }) => update(x, y)" @dragstop="$emit('dragstop')" class="power-supply ga-psu">
      <div class="img">
        <img src="../assets/powerwhite.svg" alt="Power Supply" :height="width" :width="width - 10" />
        <span :style="indexStyle">{{ index }}</span>
      </div>
    </VueDragResizeRotate>
    <div class="power-border" ref="border">
      <span class="text width"></span>
      <span class="text height"></span>
    </div>
    <div v-show="noteOpen" class="power-note" ref="note">
      <header>
        <span>Jot down a note or comment</span>
        <button class="btn" @click="noteOpen = false">
          <img src="../assets/close.svg" alt="Close" />
        </button>
      </header>
      <textarea v-model="noteValue" />
      <footer>
        <button class="btn ga-psu-note" @click="saveNote">Save</button>
      </footer>
    </div>
  </div>
</template>

<script>
import VueDragResizeRotate from './Drr/drr.vue';

export default {
  name: 'PowerSupply',
  props: {
    wallWidth: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0;
      },
    },
    wallHeight: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0;
      },
    },
    wallScaleFactor: {
      type: Number,
      required: true,
      validator(value) {
        return value > 0;
      },
    },
    measurementUnit: {
      type: String,
      required: true,
      validator(value) {
        return value === 'metric' || value === 'imperial';
      },
    },
    activeStep: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0;
      },
    },
    x: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0;
      },
    },
    y: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0;
      },
    },
    scale: {
      type: Number,
      default() {
        return 1;
      },
      validator(value) {
        return value > 0;
      },
    },
    note: {
      type: String,
      default() {
        return '';
      },
    },
    index: {
      type: Number,
      default() {
        return 1;
      },
      validator(value) {
        return value >= 1;
      },
    },
  },
  watch: {
    wallWidth() {
      this.update(this.newX, this.newY);
    },
    wallHeight() {
      this.update(this.newX, this.newY);
    },
    measurementUnit() {
      this.update(this.newX, this.newY);
    },
    activeStep(step) {
      if (step === 1) {
        this.update(this.newX, this.newY);
      }
    },
    note(newNote) {
      this.noteValue = newNote;
    },
  },
  mounted() {
    this.$root.$on('update-power-border', ({ index, x, y }) => {
      if (index === this.index - 1) {
        this.update(x, y);
      }
    });
  },
  data() {
    return {
      newX: this.x,
      newY: this.y,
      noteOpen: false,
      noteValue: this.note,
      width: document.documentElement.clientWidth * 0.02,
    };
  },
  computed: {
    indexStyle() {
      // percentages of width are trial and error
      return {
        backgroundColor: 'white',
        border: '1px solid #DADEE1',
        borderRadius: '5px',
        fontSize: `${this.width * 18 / 28.8}px`,
        position: 'absolute',
        left: '50%',
        height: `${this.width}px`,
        width: `${this.width}px`,
        lineHeight: 1.5,
        paddingRight: `${this.width * 10 / 28.8}px`,
        textAlign: 'right',
        zIndex: -1,
      };
    },
  },
  methods: {
    formattedPowerMeasurement(value) {
      const cm = value / this.wallScaleFactor;
      const converted = cm / (this.measurementUnit === 'metric' ? 1 : 2.54);
      const unit = this.measurementUnit === 'metric' ? 'cm' : 'in';
      return `${Math.round(converted)} ${unit}`;
    },
    update(x, y) {
      this.newX = x;
      this.newY = y;

      const { border, note } = this.$refs;
      const [widthText] = border.getElementsByClassName('width');
      const [heightText] = border.getElementsByClassName('height');
      const display = this.activeStep === 1 ? 'block' : 'none';
      // if note is display: none, we need to temporarily show it to calculate its height
      const mustToggleNoteDisplay = !this.noteOpen;
      if (mustToggleNoteDisplay) {
        this.noteOpen = true;
      }
      const { clientHeight: noteHeight } = note;
      if (mustToggleNoteDisplay) {
        this.noteOpen = false;
      }

      if (x < this.wallWidth * 0.5) {
        border.style.cssText = `display: ${display}; height: ${this.wallHeight - y}px; width: ${x}px; left 0; bottom: 0; border-right: 2px dashed #DADEE1; border-top: 2px dashed #DADEE1; align-items: flex-start; justify-content: flex-end`;
        widthText.style.cssText = 'left: 15px; top: -30px;';
        widthText.innerText = `${this.formattedPowerMeasurement(x)}`;
        heightText.style.cssText = 'right: -30px; bottom: 15px; transform: rotate(-90deg);';
        heightText.innerText = `${this.formattedPowerMeasurement(this.wallHeight - y)}`;
        note.style.left = `${x + 50}px`;
        note.style.right = '';
        note.style.top = `${Math.min(y - 15, this.wallHeight - noteHeight)}px`;
      } else {
        border.style.cssText = `display: ${display}; height: ${this.wallHeight - y}px; width: ${this.wallWidth - x}px; right: 0; bottom: 0; border-left: 2px dashed #DADEE1; border-top: 2px dashed #DADEE1; align-items: flex-start; justify-content: flex-start`;
        widthText.style.cssText = 'right: 15px; top: -30px;';
        widthText.innerText = `${this.formattedPowerMeasurement(this.wallWidth - x)}`;
        heightText.style.cssText = 'bottom: 15px; left: -30px; transform: rotate(-90deg);';
        heightText.innerText = `${this.formattedPowerMeasurement(this.wallHeight - y)}`;
        note.style.left = '';
        note.style.right = `${this.wallWidth - x + 30}px`;
        note.style.top = `${Math.min(y - 15, this.wallHeight - noteHeight)}px`;
      }

      if (y < this.wallHeight * 0.5) {
        widthText.style.top = '15px';
      }

      const xCm = (x < this.wallWidth * 0.5 ? x : this.wallWidth - x) / this.wallScaleFactor;
      const yCm = (this.wallHeight - y) / this.wallScaleFactor;
      this.$emit('update', { x, y, xCm, yCm });
    },
    saveNote() {
      this.noteOpen = false;
      this.$emit('note', this.noteValue);
    },
  },
  components: {
    VueDragResizeRotate,
  },
};
</script>

<style scoped>
.drr:before, .drr:hover:before {
  /* Remove draggable outline from vue-drag-resize-rotate */
  outline: none !important;
}

.power-supply {
  z-index: 3;
}

.power-supply .img {
  background-color: #3EAF29;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.power-border {
  position: absolute;
  z-index: 2;
}

.power-border .text {
  color: #AAA;
  font-size: 12px;
  position: absolute;
}

.power-note {
  background-color: white;
  border: 1px solid #CCC;
  border-radius: 8px;
  font-size: 14px;
  padding: 10px;
  position: absolute;
  width: 250px;
  z-index: 3;
}

.power-note header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.power-note header img {
  width: 20px;
}

.power-note textarea {
  border: 1px solid #F2F2F2;
  border-radius: 8px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 14px;
  padding: 10px;
  resize: none;
  height: 100px;
  width: 100%;
}

.power-note footer {
  display: flex;
  justify-content: flex-end;
}

.power-note footer .btn {
  font-weight: bold;
  margin-right: 5px;
  text-transform: uppercase;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .power-supply .title {
    align-items: baseline;
  }
}
</style>
