<template>
  <ModalBase :title="title" width="750px" :open="open" @close="$emit('close')">
    <div class="modal-body">
      <div class="text">
        All panels are required to be connected together via linker for them to work. To connect the panels the linkers are needed inside the linker slots on the edge of the panels.
      </div>
      <img :src="image" :alt="title" class="image" :style="imageStyle" />
    </div>
  </ModalBase>
</template>

<script>
import ModalBase from './ModalBase.vue';
import lpImage from '../assets/lplinkerview.svg';
import canvasImage from '../assets/canvaslinkerview.svg';
import shapesImage from '../assets/shapeslinkerview.svg';

export default {
  name: 'LinkerGuide',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    model: {
      type: String,
      required: true,
      validator(value) {
        return ['NL22', 'NL29', 'NL42'].includes(value);
      },
    }
  },
  computed: {
    title() {
      switch (this.model) {
        case 'NL22': return 'Nanoleaf Light Panels';
        case 'NL29': return 'Nanoleaf Canvas';
        case 'NL42': return 'Nanoleaf Shapes';
      }
      return '';
    },
    image() {
      switch (this.model) {
        case 'NL22': return lpImage;
        case 'NL29': return canvasImage;
        case 'NL42': return shapesImage;
      }
      return lpImage;
    },
    imageStyle() {
      const width = this.model === 'NL29' ? '20%' : '25%';
      return { width };
    },
  },
  components: {
    ModalBase,
  },
};
</script>

<style scoped>
.modal-body {
  display: flex;
  justify-content: space-between;
  min-height: 200px;
}

.text {
  width: 50%;
}

.image {
  align-self: flex-start;
  padding-bottom: 2%;
  padding-right: 15%;
}
</style>
