<template>
  <div class="wrapper" @mouseover="showControls" @mouseout="hideControls">
    <label class="prefix">{{ prefix }}</label>
    <input :value="valueWithUnit" @change="change">
    <div class="controls" ref="controls">
      <button @click="changeOnce(1)" @mousedown="changeInterval(1)" @mouseup="clearInterval">+</button>
      <button @click="changeOnce(-1)" @mousedown="changeInterval(-1)" @mouseup="clearInterval">-</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DimensionInput',
  props: {
    prefix: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      required: true,
      validator(value) {
        return ['metric', 'imperial'].includes(value);
      },
    },
    value: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0;
      },
    },
  },
  data() {
    return {
      controlInterval: 100,
      interval: null,
    };
  },
  computed: {
    valueWithUnit() {
      const unit = this.unit === 'metric' ? 'cm' : 'in';
      return `${this.value} ${unit}`;
    },
  },
  methods: {
    showControls() {
      this.$refs.controls.style.visibility = 'visible';
    },
    hideControls() {
      this.$refs.controls.style.visibility = 'hidden';
    },
    change(e) {
      const newValue = parseInt(e.target.value.replace('cm', '').replace('in', '').trim(), 10);
      if (Number.isNaN(newValue) || newValue < 0 || newValue === this.value) {
        this.$forceUpdate();
      } else {
        this.$emit('change', newValue);
      }
    },
    changeOnce(n) {
      this.$emit('change', this.value + n);
    },
    changeInterval(n) {
      this.interval = setInterval(() => {
        this.$emit('change', this.value + n);
      }, this.controlInterval);
    },
    clearInterval() {
      clearInterval(this.interval);
    },
  },
};
</script>

<style scoped>
.wrapper {
  background-color: #F1F2F2;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
}

.prefix {
  color: #BBB;
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
}

input {
  background-color: #F1F2F2;
  text-align: right;
  width: 70%;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  visibility: hidden;
}

.controls button {
  width: 20px;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  input {
    padding: 10px 0 10px 8px;
  }
}
</style>
