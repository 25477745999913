import SecureLS from 'secure-ls';

const ls = new SecureLS({ encodingType: 'aes' });
const STATE_KEY = 'state';

const secureStorage = {
  getState() {
    return ls.get(STATE_KEY);
  },
  setState(state) {
    ls.set(STATE_KEY, state);
  },
  deleteState() {
    ls.remove(STATE_KEY);
  },
};

export default secureStorage;
