<template>
  <Toggle :left-active="metric" left="cm" right="in" @change="$emit('change', $event ? 'metric' : 'imperial')" />
</template>

<script>
import Toggle from './Toggle.vue';

export default {
  name: 'MeasurementUnitToggle',
  props: {
    metric: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  components: {
    Toggle,
  },
};
</script>
