<template>
  <div>
    <section>
      <h2>Smart panels</h2>
      <table>
        <tr v-if="!isB2B">
          <td><img :src="productImage" /></td>
          <td class="bold">Nanoleaf {{ productTitle }}</td>
          <td class="main">{{ smarterKitTitle }} Smarter Kit ({{ smarterKitSize }})</td>
          <td class="bold qty">x1</td>
        </tr>
        <tr v-for="(pack, $index) in expansionPacks" :key="$index">
          <td v-if="isB2B && $index === 0"><img :src="productImage" /></td>
          <td v-else></td>
          <td v-if="isB2B && $index === 0" class="bold">Nanoleaf {{ productTitle }}</td>
          <td v-else></td>
          <td class="main">{{ pack.title }} Expansion Pack ({{ pack.size }})</td>
          <td class="bold qty">x{{ pack.count }}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr class="subtotal">
          <td></td>
          <td></td>
          <td class="bold main">Subtotal {{ totalItemCount }}</td>
          <td></td>
        </tr>
      </table>
    </section>
    <section>
      <h2>Accessories</h2>
      <table>
        <tr v-for="(powerSupply, $index) in powerSupplies" :key="'powerSupply_' + $index">
          <td><img v-show="$index === 0" src="../assets/powergreen.svg" /></td>
          <td class="bold">{{ $index === 0 ? 'Power Supply' : '' }}</td>
          <td class="main">{{ powerSupply.title }}</td>
          <td class="bold qty">x{{ powerSupply.count }}</td>
        </tr>
        <tr v-for="(plate, $index) in mountingPlates" :key="'plate_' + $index">
          <td><img v-show="$index === 0" :src="mountingImage" /></td>
          <td class="bold">{{ $index === 0 ? 'Mounting' : '' }}</td>
          <td class="main">{{ productTitle }} mounting plate ({{ plate.size }}pc)</td>
          <td class="bold qty">x{{ plate.count }}</td>
        </tr>
        <tr v-show="activeModel === 'NL42'">
          <td><img src="../assets/shapesmountgreen.svg" /></td>
          <td class="bold">Mounting</td>
          <td class="main">Shapes mounting plate (incl.)</td>
          <td class="bold qty">x{{ totalItemCount }}</td>
        </tr>
      </table>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import lightPanelsImage from '../assets/lightpanelsgreen.svg';
import canvasImage from '../assets/canvasgreen.svg';
import shapesImage from '../assets/shapesgreen.svg';

import lightPanelsMountingImage from '../assets/lightpanelsmountgreen.svg';
import canvasMountingImage from '../assets/canvasmountgreen.svg';
import shapesMountingImage from '../assets/shapesmountgreen.svg';

export default {
  name: 'Summary',
  computed: {
    ...mapGetters([
      'activeModel',
      'totalItemCount',
    ]),
    ...mapState([
      'isB2B',
      'models',
    ]),
    productImage() {
      switch (this.activeModel) {
        case 'NL22': return lightPanelsImage;
        case 'NL29': return canvasImage;
        case 'NL42': return shapesImage;
      }
      return null;
    },
    productTitle() {
      switch (this.activeModel) {
        case 'NL22': return 'Light Panels';
        case 'NL29': return 'Canvas';
        case 'NL42': return 'Shapes';
      }
      return null;
    },
    smarterKitTitle() {
      if (this.activeModel === 'NL42') {
        const [shape] = this.models[2].children.filter((shape) => shape.smarterKitSelected);
        if (shape) {
          return shape.title;
        }
      }
      return this.productTitle;
    },
    smarterKitSize() {
      if (this.activeModel === 'NL22') {
        return this.models[0].smarterKitSize;
      } else if (this.activeModel === 'NL29') {
        return this.models[1].smarterKitSize;
      } else if (this.activeModel === 'NL42') {
        const [shape] = this.models[2].children.filter((shape) => shape.smarterKitSelected);
        if (shape) {
          return shape.smarterKitSize;
        }
      }
      return null;
    },
    expansionPacks() {
      let packs;
      if (this.activeModel === 'NL22') {
        packs = this.models[0].expansionPacks.map((pack) => ({ ...pack, title: 'Light Panels' }));
      } else if (this.activeModel === 'NL29') {
        packs = this.models[1].expansionPacks.map((pack) => ({ ...pack, title: 'Canvas' }));
      } else if (this.activeModel === 'NL42') {
        packs = this.models[2].children.reduce((packs, child) =>
          packs.concat(child.expansionPacks.map((pack) => ({ ...pack, title: `Shapes ${child.title}` }))), []);
      }
      if (packs) {
        return packs.filter((pack) => pack.count > 0);
      }
      return null;
    },
    powerSupplies() {
      let index = 0;
      if (this.activeModel === 'NL29') {
        index = 1;
      } else if (this.activeModel === 'NL42') {
        index = 2;
      }
      const psIndex = this.models[index].powerSupplies.findIndex((ps) => ps.selected);
      if (psIndex === -1) {
        return null;
      }
      const { count, wattage } = this.models[index].powerSupplies[psIndex];
      const title = `${wattage}W PSU`;
      // first power supply of lower wattage is included in smarter kit
      if (psIndex === 0) {
        return [
          { title: `${title} (incl.)`, count: 1 },
          { title, count: count - 1 },
        ].filter(({ count }) => count > 0);
      }
      return [{ title, count }];
    },
    mountingImage() {
      switch (this.activeModel) {
        case 'NL22': return lightPanelsMountingImage;
        case 'NL29': return canvasMountingImage;
        case 'NL42': return shapesMountingImage;
      }
      return null;
    },
    mountingPlates() {
      if (this.activeModel === 'NL42') return [];
      const index = this.activeModel === 'NL22' ? 0 : 1;
      const model = this.models[index];
      return model.mountingPlatesSelected
        ? model.mountingPlates.filter((plates) => plates.count > 0)
        : [];
    },
  },
};
</script>

<style scoped>
section {
  margin-bottom: 30px;
}

section h2 {
  margin-bottom: 20px;
}

table {
  border-collapse: collapse;
  font-size: 12px;
  width: 100%;
}

td {
  padding: 5px;
}

.bold {
  font-weight: bold;
}

td.main {
  width: 50%;
}

td.qty {
  text-align: right;
}

.subtotal {
  background-color: #ECF6EA;
}

@media only screen and (min-device-width: 1025px) and (orientation:landscape) {
  table {
    font-size: 16px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  section h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }
}
</style>
