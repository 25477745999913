<template>
  <AddMultiple :model-name="modelName" :title="title" subtitle="Subtotal Panels" :count="count" :size="size" @change="$emit('change', $event)" />
</template>

<script>
import AddMultiple from './AddMultiple.vue';

export default {
  name: 'AddExpansionPacks',
  props: {
    modelName: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0;
      },
    },
    size: {
      type: Number,
      required: true,
      validator(value) {
        return value>= 0;
      },
    },
  },
  computed: {
    title() {
      return `${this.size} Panels Expansion Pack`;
    },
  },
  components: {
    AddMultiple,
  },
};
</script>
