const clone = (obj) => JSON.parse(JSON.stringify(obj));
const undoStack = [];
const redoStack = [];
const getCurrentState = () => clone(undoStack[undoStack.length - 1]);

// must call setInitialState first
// top of undoStack is current state
const undoController = {
  setInitialState(state) {
    undoStack[0] = clone(state);
  },
  push(state) {
    undoStack.push(clone(state));
    redoStack.splice(0, redoStack.length);
  },
  canUndo: () => undoStack.length > 1,
  undo() {
    if (this.canUndo()) {
      redoStack.push(undoStack.pop());
      return getCurrentState();
    }  
  },
  canRedo: () => redoStack.length > 0,
  redo() {
    if (this.canRedo()) {
      undoStack.push(redoStack.pop());
      return getCurrentState();
    }  
  },
};

export default undoController;
