function getDesignCode(urlOrCode) {
  const code = urlOrCode.trim();
  try {
    let url = null;
    if (code.includes(location.origin)) {
      url = new URL(code);
    } else if (code.includes(location.host)) {
      url = new URL(`http://${code}`);
    }
    return url ? url.searchParams.get('design') : code;
  } catch {
    return code;
  }
}

function loadDesign(urlOrCode) {
  const designCode = getDesignCode(urlOrCode);
  if (designCode) {
    const url = new URL(location.href);
    url.searchParams.set('design', designCode);
    location.replace(url.toString());
  }
}

export default loadDesign;
