<template>
  <div class="wrapper">
    <div class="size"><img :src="icon" :alt="modelName" />{{ size }} Panels/box</div>
    <div class="count">x {{ count }}</div>
  </div>
</template>

<script>
import canvasIcon from '../assets/canvasicon.svg';
import hexagonIcon from '../assets/hexagonicon.svg';
import triangleIcon from '../assets/triangleicon.svg';
import miniTriangleIcon from '../assets/minitriangleicon.svg';
import linesIcon from '../assets/linesicon.svg';

export default {
  name: 'BoxCount',
  props: {
    modelName: {
      type: String,
      validator(value) {
        return ['Light Panels', 'Canvas', 'Hexagons', 'Triangles', 'Mini Triangles', 'Lines', 'Lines Squared'].includes(value);
      },
    },
    size: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  computed: {
    icon() {
      switch (this.modelName) {
        case 'Light Panels': return triangleIcon;
        case 'Canvas': return canvasIcon;
        case 'Hexagons': return hexagonIcon;
        case 'Triangles': return triangleIcon;
        case 'Mini Triangles': return miniTriangleIcon;
        case 'Lines': return linesIcon;
        case 'Lines Squared': return linesIcon;
      }
      return null;
    },
  },
}
</script>

<style scoped>
.wrapper {
  background-color: #F8F8F8;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 20px;
  width: 100%;
}

.size {
  display: flex;
  align-items: center;
}

.size img {
  margin-right: 5px;
  width: 15px;
}

.count {
  border-left: 1px solid #CCC;
  padding-left: 10px;
}
</style>
