const COOKIE_EXPIRY_DAYS = 30;
const EUROPEAN_COUNTRY_CODES = [
  'AT','BE','BG','CY','CZ','DK','EE','FI','FR',
  'DE','EL','HR','HU','IE','IT','LV','LT','LU',
  'MT','NL','PL','PT','RO','SK','SI','ES','SE',
];
const GEOLOCATION_COOKIE_NAME = 'countryCode';
const REQUEST_URL = 'https://geo.helper.nanoleaf.me';

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  return parts.length === 2 ? parts.pop().split(';').shift() : null;
}

function setCookie(name, value, days) {
  const expires = new Date();
  expires.setDate(expires.getDate() + days);
  document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`;
}

export async function getCountryCode() {
  const countryCode = getCookie(GEOLOCATION_COOKIE_NAME);
  if (countryCode) {
    return countryCode;
  } else {
    const res = await fetch(REQUEST_URL, { method: 'HEAD' });
    const countryCode = res.headers.get('X-Client-Geo-Location');
    if (countryCode) {
      setCookie(GEOLOCATION_COOKIE_NAME, countryCode, COOKIE_EXPIRY_DAYS);
    }
    return countryCode;
  }
}

export function getShopCountryCode(countryCode) {
  if (['CA', 'US', 'AU', 'GB', 'HK'].includes(countryCode)) {
    return countryCode;
  } else if (EUROPEAN_COUNTRY_CODES.includes(countryCode)) {
    return 'EU';
  } else if (countryCode === 'NZ') {
    return 'AU';
  } else {
    return 'HK';
  }
}
