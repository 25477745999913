<template>
  <ModalBase title="Deleting current design" width="400px" :open="open" @close="$emit('cancel')">
    <div class="modal-body">
      <p>By switching to a different product, you will be losing your current design. Do you wish to continue?</p>
      <div class="modal-actions">
        <button class="btn" @click="$emit('cancel')">Cancel</button>
        <button class="btn danger" @click="$emit('ok')">Delete and Switch</button>
      </div>
    </div>
  </ModalBase>
</template>

<script>
import ModalBase from './ModalBase.vue';

export default {
  name: 'SwitchProduct',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ModalBase,
  },
};
</script>

<style scoped>
.modal-body p {
  font-size: 12px;
  width: 85%;
}
</style>
