<template>
  <ModalBase title="Oops, too many panels!" width="400px" :open="open" @close="$emit('close')">
    <div class="modal-body">
      <p>You have added too many panels to fit on your wall. Please increase your wall size before adding more panels.</p>
      <div class="modal-actions">
        <button class="btn primary" @click="$emit('close')">Got it</button>
      </div>
    </div>
  </ModalBase>
</template>

<script>
import ModalBase from './ModalBase.vue';

export default {
  name: 'TooManyPanels',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ModalBase,
  },
};
</script>
