<template>
  <ModalBase title="Resume Work" width="350px" :open="open" @close="$emit('close')">
    <div class="modal-body">
      <p>Enter your design code and pick up where you left off.</p>
      <div class="input-wrapper">
        <input type="text" v-model="designCode" ref="designCode" />
        <button class="btn btn-load-design ga-load" @click="loadDesign">Load</button>
      </div>
    </div>
  </ModalBase>
</template>

<script>
import ModalBase from './ModalBase.vue';
import loadDesign from '../loadDesign';

export default {
  name: 'LoadDesign',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    this.$refs.designCode.addEventListener('keydown', ({ keyCode }) => {
      if (this.open && keyCode === 13) {
        this.loadDesign();
      }
    });
  },
  data() {
    return {
      designCode: '',
    };
  },
  methods: {
    loadDesign() {
      loadDesign(this.designCode);
    },
  },
  components: {
    ModalBase,
  },
};
</script>

<style scoped>
.input-wrapper {
  display: flex;
  padding: 20px 0 35px;
}

.input-wrapper input {
  border-radius: 25px 0 0 25px;
  padding: 10px;
  width: calc(100% - 100px);
}

.btn-load-design {
  background-color: #3B4C55;
  color: white;
  border-radius: 0 25px 25px 0;
  font-weight: bold;
  padding: 10px;
  text-transform: uppercase;
  width: 80px;
  font-size: 12px;
}

.modal-body p {
  width: 85%;
}

.close-button {
  position: absolute;
  top: 28px;
  right: 50px;
}
</style>
