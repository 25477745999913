import Vue from 'vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import App from './App.vue';
import store from './store';

Vue.config.productionTip = false;
Vue.use(Toast, {
  hideProgressBar: true,
  icon: false,
  timeout: 3000,
  toastClassName: 'toast',
});

new Vue({
  render: h => h(App),
  store,
}).$mount('#app');
