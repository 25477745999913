<template>
  <ModalBase title="Contact Us" width="400px" :open="open" @close="$emit('close')">
    <div class="modal-body">
      <div class="input-wrapper">
        <label>First Name</label>
        <input type="text" v-model="firstName" />
      </div>
      <div class="input-wrapper">
        <label>Last Name</label>
        <input type="text" v-model="lastName" />
      </div>
      <div class="input-wrapper">
        <label>Email</label>
        <input type="email" v-model="email" />
      </div>
      <div class="input-wrapper">
        <label>Country</label>
        <input type="text" v-model="country" />
      </div>
      <div class="input-wrapper">
        <label>Message</label>
        <textarea v-model="message" />
      </div>
      <div class="checkbox">
        <input type="checkbox" v-model="optin" />
        <label>Opt in for the latest news and discounts</label>
      </div>
      <div class="submit">
        <button class="btn btn-submit ga-contact" :disabled="submitting" @click="submit">Send</button>
      </div>
    </div>
    <Error message="Sorry, there was an error submitting the form." :open="errorModalOpen" @close="errorModalOpen = false" />
  </ModalBase>
</template>

<script>
import Error from './Error.vue';
import ModalBase from './ModalBase.vue';

export default {
  name: 'Contact',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      country: '',
      message: '',
      optin: false,
      submitting: false,
      errorModalOpen: false,
    };
  },
  methods: {
    async submit() {
      this.submitting = true;
      const data = {
        fields: [
          { name: 'firstname', value: this.firstName },
          { name: 'lastname', value: this.lastName },
          { name: 'email', value: this.email },
          { name: 'country', value: this.country },
          { name: 'how_can_we_help_', value: this.message },
        ],
      };
      try {
        const res = await fetch('https://api.hsforms.com/submissions/v3/integration/submit/5121114/17201c43-4a28-44a9-8f80-83706789e564', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });
        if (res.ok) {
          this.$emit('close');
          // wait for animation
          setTimeout(() => {
            this.firstName = '';
            this.lastName = '';
            this.email = '';
            this.country = '';
            this.message = '';
            this.optin = false;
            this.submitting = false;
          }, 500);
        } else {
          this.errorModalOpen = true;
          this.submitting = false;
        }
      } catch {
        this.errorModalOpen = true;
        this.submitting = false;
      }
    },
  },
  components: {
    Error,
    ModalBase,
  },
};
</script>

<style scoped>
.input-wrapper {
  margin-bottom: 20px;
}

.input-wrapper label {
  display: block;
  margin-bottom: 10px;
}

.input-wrapper input {
  width: 100%;
}

.input-wrapper textarea {
  border: 1px solid #F2F2F2;
  border-radius: 8px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 14px;
  padding: 10px;
  resize: none;
  height: 100px;
  width: 100%;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  width: 100%;
}

.checkbox input {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}

.submit {
  padding-bottom: 30px;
  padding-top: 15px;
  text-align: right;
}

.btn-submit {
  background-color: #3B4C55;
  border-radius: 25px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  text-transform: uppercase;
}
</style>
