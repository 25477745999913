import Vue from 'vue';
import Vuex from 'vuex';

import Toast from './components/Toast.vue';

import lightPanelImage from './assets/lightpanelsdark.svg';
import lightPanelWhiteImage from './assets/lightpanelswhite.svg';
import canvasImage from './assets/canvasdark.svg';
import canvasWhiteImage from './assets/canvaswhite.svg';
import shapesImage from './assets/shapesdark.svg';
import shapesWhiteImage from './assets/shapeswhite.svg';
import linesImage from './assets/linesdark.svg';
import linesWhiteImage from './assets/lineswhite.svg';

import convertState from './convertState';
import getDesignCode from './getDesignCode';
import secureStorage from './secureStorage';
import undoController from './undoController';

Vue.use(Vuex);

const isB2B = new URLSearchParams(location.search).get('b2b') === 'true';
const defaultState = {
  version: 2,
  isB2B,
  loadingFromDesignCode: false,
  pdfReady: false,
  saved: false,
  designCode: '',
  activeId: -1,
  models: [
    { 
      id: 0,
      model: 'NL22',
      title: 'Light Panels',
      image: lightPanelImage,
      imageWhite: lightPanelWhiteImage,
      smarterKitSize: 9,
      smarterKitSelected: false,
      smarterKitDisabled: false,
      expansionPacks: [
        { size: 3, count: 0, b2b: false },
        { size: 30, count: 0, b2b: true },
      ],
      count: 0,
      positions: [], // [{ x: number, y: number, rotation: number, isSnapped: boolean }]
      powerSupplies: [
        {
          wattage: 60,
          title: '60W Power Supply',
          description: 'Every Smarter Kit contains one power supply.',
          count: 0,
          selected: false,
          b2b: true,
        },
      ],
      mountingPlates: [
        {
          size: 12,
          title: 'Screw mount kit (12pc)',
          count: 0,
        },
      ],
      mountingPlatesSelected: false,
      children: [],
    },
    { 
      id: 1,
      model: 'NL29',
      title: 'Canvas',
      image: canvasImage,
      imageWhite: canvasWhiteImage,
      smarterKitSize: 9,
      smarterKitSelected: false,
      smarterKitDisabled: false,
      expansionPacks: [
        { size: 4, count: 0, b2b: false },
        { size: 25, count: 0, b2b: true },
      ],
      count: 0,
      positions: [], // [{ x: number, y: number, rotation: number, isSnapped: boolean }]
      powerSupplies: [
        {
          wattage: 25,
          title: '25W Power Supply',
          description: 'Every Smarter Kit contains one power supply.',
          count: 0,
          selected: false,
          b2b: false,
        },
        {
          wattage: 75,
          title: '75W Power Supply',
          description: '',
          count: 0,
          selected: false,
          b2b: true,
        },
      ],
      mountingPlates: [
        {
          size: 9,
          title: 'Screw mount kit (9pc)',
          count: 0,
        },
        {
          size: 25,
          title: 'Screw mount kit (25pc)',
          count: 0,
        },
      ],
      mountingPlatesSelected: false,
      children: [],
    },
    { 
      model: 'NL42',
      title: 'Shapes',
      image: shapesImage,
      imageWhite: shapesWhiteImage,
      powerSupplies: [
        {
          wattage: 42,
          title: '42W Power Supply',
          description: 'Every Smarter Kit contains one power supply.',
          count: 0,
          selected: false,
          b2b: false,
        },
        {
          wattage: 75,
          title: '75W Power Supply',
          description: '',
          count: 0,
          selected: false,
          b2b: true,
        },
      ],
      mountingPlates: [
        {
          size: 1,
          title: '',
          count: 0,
        },
      ],
      mountingPlatesSelected: false,
      children: [
        {
          id: 2,
          title: 'Hexagons',
          smarterKitSize: 7,
          smarterKitSelected: false,
          smarterKitDisabled: false,
          expansionPacks: [
            { size: 3, count: 0, b2b: false },
            { size: 25, count: 0, b2b: true },
          ],
          count: 0,
          positions: [], // [{ x: number, y: number, rotation: number, isSnapped: boolean }]
        },
        {
          id: 3,
          title: 'Triangles',
          smarterKitSize: 7,
          smarterKitSelected: false,
          smarterKitDisabled: false,
          expansionPacks: [
            { size: 3, count: 0, b2b: false },
            { size: 25, count: 0, b2b: true },
          ],
          count: 0,
          positions: [], // [{ x: number, y: number, rotation: number, isSnapped: boolean }]
        },
        {
          id: 4,
          title: 'Mini Triangles',
          smarterKitSize: 5,
          smarterKitSelected: false,
          smarterKitDisabled: false,
          expansionPacks: [
            { size: 10, count: 0, b2b: true },
          ],
          count: 0,
          positions: [], // [{ x: number, y: number, rotation: number, isSnapped: boolean }]
        },
      ],
    },
    {
      model: 'NL59',
      title: 'Lines',
      image: linesImage,
      imageWhite: linesWhiteImage,
      powerSupplies: [
        {
          wattage: 42,
          title: '42W Power Supply',
          description: 'Every Smarter Kit contains one power supply.',
          count: 0,
          selected: false,
          b2b: true,
        },
      ],
      mountingPlates: [
        {
          size: 1,
          title: '',
          count: 0,
        },
      ],
      mountingPlatesSelected: false,
      children: [
        {
          id: 5,
          title: 'Lines',
          smarterKitSize: 9,
          smarterKitSelected: false,
          smarterKitDisabled: false,
          expansionPacks: [
            { size: 3, count: 0, b2b: false },
          ],
          count: 0,
          positions: [], // [{ x: number, y: number, rotation: number, isSnapped: boolean }]
          puckPositions: [], // [{ x: number, y: number, rotation: number, isSnapped: boolean }]
        },
        {
          id: 6,
          title: 'Lines Squared',
          smarterKitSize: 9,
          smarterKitSelected: false,
          smarterKitDisabled: false,
          expansionPacks: [
            { size: 3, count: 0, b2b: false },
          ],
          count: 0,
          positions: [], // [{ x: number, y: number, rotation: number, isSnapped: boolean }]
          puckPositions: [], // [{ x: number, y: number, rotation: number, isSnapped: boolean }]
        },
      ],
    },
  ],
  kits: [], // store what size kits the user added for toast text
  wallName: 'Untitled Wall',
  wallWidth: 150,
  wallWidthPx: 0,
  windowWidthPx: document.documentElement.clientWidth,
  wallHeight: 90,
  wallHeightPx: 0,
  windowHeightPx: document.documentElement.clientHeight,
  wallZoom: 1,
  layoutRotation: 0,
  // design dimensions come from Wall and are always in cm
  designWidth: 0,
  designHeight: 0,
  measurementUnit: 'metric', // 'metric' | 'imperial'
  controllerPosition: { x: 0, y: 0, rotation: 0, type: '' }, // type can be 'hexagon' or 'square' for lines
  powerSupplies: [], // [{ x: number, y: number, xCm: number, yCm: number, note: string }]
  shapesMountingToastShown: false,
  activeStep: 0,
  steps: [
    { title: 'Design', subtitle: 'Create Your Design' },
    { title: 'Power', subtitle: 'Mark Power Supply (optional)' },
    { title: 'Mounting', subtitle: 'Learn More About Mounting' },
    { title: isB2B ? 'Contact' : 'Summary', subtitle: isB2B ? 'Contact Sales' : 'Product Summary' },
  ],
  canUndo: false,
  canRedo: false,
  linkerView: false,
  layoutStringData: [],
};

// for internal use, allow adding single panels
if (new URLSearchParams(location.search).get('internal') === 'true') {
  for (const model of defaultState.models) {
      for (const child of model.children) {
        child.expansionPacks.unshift({ size: 1, count: 0, b2b: true });
      }
      model.expansionPacks?.unshift({ size: 1, count: 0, b2b: true });
  }
}

const saveToLocalStorage = (store) => {
  store.subscribe((_, state) => {
    const stateObject = JSON.parse(JSON.stringify(state));
    stateObject.isFromLocalStorage = true;
    secureStorage.setState(stateObject);
  });
};

const setSavedStatus = (store) => {
  store.subscribe((mutation) => {
    if (mutation.type === 'doneLoadingFromDesignCode' && new URLSearchParams(location.search).get('design')) {
      // if we have loaded from design code (not local storage), then it is already saved
      store.commit('setSaved', true);
    } else if (!['setSaved', 'setStep'].includes(mutation.type)) {
      store.commit('setSaved', false);
    }
  });
};

undoController.setInitialState(defaultState);
const pushStateToUndoStack = (store) => {
  store.subscribe((mutation) => {
    // adding panels is handled by Wall component
    // removing panels is handled by setCount mutation
    const mutations = ['setRotation', 'setZoom', 'setHeight', 'setWidth'];
    if (mutations.includes(mutation.type)) {
      store.commit('pushStateToUndoStack');
    }
  });
};

function getModelById(store, id) {
  for (const model of store.models) {
    if (model.id === id) return model;
    for (const c of model.children) if (c.id === id) return c;
  }
}

const store = new Vuex.Store({
  state: defaultState,
  getters: {
    activeModel: ({ activeId }) => {
      switch (activeId) {
        case 0: return 'NL22';
        case 1: return 'NL29';
        case 2:
        case 3:
        case 4:
          return 'NL42';
        case 5:
        case 6:
          return 'NL59';
        default: return '';
      }
    },
    numLightPanels: ({ models }) => {
      return models[0].count;
    },
    numCanvases: ({ models }) => {
      return models[1].count;
    },
    numHexagons: ({ models }) => {
      return models[2].children[0].count;
    },
    numTriangles: ({ models }) => {
      return models[2].children[1].count;
    },
    numMiniTriangles: ({ models }) => {
      return models[2].children[2].count;
    },
    numShapes: (_, { numHexagons, numTriangles, numMiniTriangles }) => {
      return numHexagons + numTriangles + numMiniTriangles;
    },
    numLines: ({ models }) => {
      return models[3].children[0].count;
    },
    numLinesSquared: ({ models }) => {
      return models[3].children[1].count;
    },
    numLinesTotal: (_, { numLines, numLinesSquared }) => {
      return numLines + numLinesSquared;
    },
    totalItemCount: (_, { numLightPanels, numCanvases, numShapes, numLinesTotal }) => {
      return numLightPanels + numCanvases + numShapes + numLinesTotal;
    },
    selectedShapesSmarterKit: ({ models }) => {
      const selected = models[2].children.filter((shape) => shape.smarterKitSelected);
      return selected.length > 0 ? selected[0].title : null;
    },
    positions: ({ models }) => {
      return {
        panel: models[0].positions,
        canvas: models[1].positions,
        hexagon: models[2].children[0].positions,
        triangle: models[2].children[1].positions,
        miniTriangle: models[2].children[2].positions,
        lines: models[3].children[0].positions,
        linesPuck: models[3].children[0].puckPositions,
        linesSquared: models[3].children[1].positions,
        linesSquaredPuck: models[3].children[1].puckPositions,
      };
    },
    // Wall should always receive wall width/height in cm
    wallWidth: ({ measurementUnit, wallWidth }) => {
      const factor = measurementUnit === 'metric' ? 1 : 2.54;
      return wallWidth * factor;
    },
    wallHeight: ({ measurementUnit, wallHeight }) => {
      const factor = measurementUnit === 'metric' ? 1 : 2.54;
      return wallHeight * factor;
    },
    totalMountingPlates: ({ activeId, models }, { totalItemCount }) => {
      if (activeId === -1) return 0;
      else if (activeId >= 2) return totalItemCount;
      else return models[activeId].mountingPlates.reduce((count, plates) => count + plates.size * plates.count, 0);
    },
    stepOutOf: ({ activeStep, steps }) => {
      return `(${activeStep + 1}/${steps.length})`;
    },
  },
  mutations: {
    initialize(state, data) {
      convertState(data);
      const newState = {
        ...data,
        pdfReady: false,
        designCode: data.isFromLocalStorage ? data.designCode : '',
        activeStep: 0,
        canUndo: false,
        canRedo: false,
        linkerView: false,
      };
      undoController.setInitialState(newState);
      Object.assign(state, newState, { loadingFromDesignCode: true });
    },
    setSaved(state, saved) {
      state.saved = saved;
    },
    setDesignCode(state, code) {
      state.designCode = code;
    },
    doneLoadingFromDesignCode(state) {
      // design was loaded with saved window dimensions, now that layout is adjusted, reset them to current values
      const { clientWidth, clientHeight } = document.documentElement;
      state.windowWidthPx = clientWidth;
      state.windowHeightPx = clientHeight;
      state.loadingFromDesignCode = false;
    },
    setPdfReady(state, value) {
      state.pdfReady = value;
    },
    setActiveId(state, id) {
      state.activeId = id;
      if (id !== 0) {
        state.models[0].mountingPlatesSelected = false;
      }
      if (id !== 1) {
        state.models[1].mountingPlatesSelected = false;
      }
      if (id < 2) {
        state.shapesMountingToastShown = false;
      }
    },
    setCount(state, { id, smarterKitSelected, expansionPacks, count }) {
      const model = getModelById(state, id);
      const { count: oldCount } = model;
      // Update kits
      if (count > oldCount) {
        const numShapes = state.models[2].children.reduce((total, { count }) => total + count, 0);
        const type = !state.isB2B && oldCount === 0 && (id <= 1 || count === numShapes) ? 'Smarter Kit' : 'Expansion Pack';
        state.kits.push({ model: model.title, type, size: count - oldCount });
      } else if (count === 0 && id <= 1) {
        state.kits = [];
      } else {
        const index = [...state.kits].reverse().findIndex(({ title, size }) => title === model.title && size === oldCount - count);
        state.kits.splice(index, 1);
      }

      const defaultPosition = { x: state.wallWidthPx * 0.5, y: state.wallHeightPx * 0.5, rotation: 0, isSnapped: false };
      if (id <= 1) {
        if (count < oldCount) {
          model.positions.splice(count, oldCount - count);
        } else {
          for (let i = 0; i < count - oldCount; i++) {
            model.positions.push({ ...defaultPosition });
          }
        }

        if (id === 0) {
          model.mountingPlates[0].count = Math.ceil(count / 12);
        } else if (count < 18) {
          model.mountingPlates[0].count = Math.ceil(count / 9);
          model.mountingPlates[1].count = 0;
        } else if (count <= 25) {
          model.mountingPlates[0].count = 0;
          model.mountingPlates[1].count = 1;
        } else {
          model.mountingPlates[0].count = Math.ceil((count % 25) / 9);
          model.mountingPlates[1].count = Math.floor(count / 25);
        }

        model.smarterKitSelected = smarterKitSelected;
        model.expansionPacks = expansionPacks;
        model.count = count;
        Vue.set(state.models, id, model);
      } else if (id >= 2 && id <= 4) {
        if (count < oldCount) {
          model.positions.splice(count, oldCount - count);
        } else {
          for (let i = 0; i < count - oldCount; i++) {
            model.positions.push({ ...defaultPosition });
          }
        }

        // When selecting smarter kit for one shape, selecting it should be disabled for other shapes
        for (let i = 0; i < state.models[2].children.length; i++) {
          if (smarterKitSelected && id - 2 === i) {
            for (let j = 0; j < state.models[2].children.length; j++) {
              state.models[2].children[j].smarterKitDisabled = i !== j;
            }
          }
        }

        // When de-selecting smarter kit, re-enable selecting smarter kit and reset expansion packs and counts for all shapes
        if (!state.isB2B && !model.smarterKitDisabled && !smarterKitSelected) {
          for (let i = 0; i < state.models[2].children.length; i++) {
            state.models[2].children[i].smarterKitDisabled = false;
            for (let j = 0; j < state.models[2].children[i].expansionPacks.length; j++) {
              state.models[2].children[i].expansionPacks[j].count = 0;
            }
            state.models[2].children[i].count = 0;
          }
          state.kits = [];
        }

        model.smarterKitSelected = smarterKitSelected;
        model.expansionPacks = expansionPacks;
        model.count = count;
        state.models[2].mountingPlates[0].count = count;
        Vue.set(state.models, 2, state.models[2]);
      } else if (id <= 6) {
        const puckType = id === 5 ? 'hexagon' : 'square';
        if (state.models[3].children.every((c) => c.count === 0)) {
          state.controllerPosition = { x: 0, y: 0, rotation: 0, type: puckType };
        }

        if (count < oldCount) {
          model.positions.splice(count, oldCount - count);
          model.puckPositions.splice(count, oldCount - count);
        } else {
          for (let i = 0; i < count - oldCount; i++) {
            model.positions.push({ ...defaultPosition });
            model.puckPositions.push({ ...defaultPosition });
          }
        }

        model.smarterKitSelected = smarterKitSelected;
        model.expansionPacks = expansionPacks;
        model.count = count;
        Vue.set(state.models[3].children, id - 5, model);
      }
      // case for count > oldCount is handled by Wall component since positions are set there
      if (count < oldCount) {
        undoController.push(state);
      }

      // Update power supplies
      const index = Math.min(id, 2);
      const psus = state.models[index].powerSupplies;
      for (let i = 0; i < psus.length; i++) {
        const psu = psus[i];
        if (count === 0) {
          psu.count = 0;
          psu.selected = false;
        } else if (index === 0) {
          // LP - 30 panels per PSU
          psu.count = Math.ceil(count / 30);
        } else if (index === 1) {
          // Canvas - 1 W per panel
          psu.count = Math.ceil(count / psu.wattage);
        } else if (index === 2) {
          // Hexagons - 2 W per panel
          // Triangles - 1.5 W per panel
          // Mini Triangles - (42/78) W per panel
          const wattsNeeded = state.models[2].children[0].count * 2 + state.models[2].children[1].count * 1.5 + state.models[2].children[2].count * (42 / 78);
          psu.count = Math.ceil(wattsNeeded / psu.wattage);
        }
      }
      Vue.set(state.models[index], 'powerSupplies', psus);

      // Update power supply positions and notes
      const selectedPowerSupply = state.models[index].powerSupplies.find((psu) => psu.selected);
      if (selectedPowerSupply) {
        const { count } = selectedPowerSupply;
        if (count > state.powerSupplies.length) {
          for (let i = state.powerSupplies.length; i < count; i++) {
            state.powerSupplies.push({
              x: state.wallWidthPx - 50,
              y: state.wallHeightPx - 50,
              xCm: 0,
              yCm: 0,
              note: '',
            });
          }
        } else if (count < state.powerSupplies.length) {
          state.powerSupplies.splice(count, state.powerSupplies.length - count);
        }
      }
      Vue.set(state, 'powerSupplies', state.powerSupplies);
    },
    setMeasurementUnit(state, unit) {
      state.measurementUnit = unit;
      if (unit === 'metric') {
        state.wallWidth *= 2.54;
        state.wallHeight *= 2.54;
      } else if (unit === 'imperial') {
        state.wallWidth /= 2.54;
        state.wallHeight /= 2.54;
      }
      state.wallWidth = Math.round(state.wallWidth);
      state.wallHeight = Math.round(state.wallHeight);
    },
    setName(state, name) {
      state.wallName = name;
    },
    setInitialWidthAndHeight(state, { width, height }) {
      state.wallWidth = width;
      state.wallHeight = height;
    },
    setWidth(state, width) {
      state.wallWidth = width;
    },
    setHeight(state, height) {
      state.wallHeight = height;
    },
    setPxDimensions(state, { width, height }) {
      state.wallWidthPx = width;
      state.wallHeightPx = height;
    },
    setDesignDimensions(state, { width, height }) {
      state.designWidth = width;
      state.designHeight = height;
    },
    setZoom(state, zoom) {
      state.wallZoom = zoom;
    },
    setRotation(state, rotation) {
      state.layoutRotation = rotation;
    },
    setPosition(state, { type, index, x, y, rotation, isSnapped = true }) {
      const setObj = { x, y, rotation, isSnapped };
      if (type === 'panel') {
        Vue.set(state.models[0].positions, index, setObj);
      } else if (type === 'canvas') {
        Vue.set(state.models[1].positions, index, setObj);
      } else if (type === 'hexagon') {
        Vue.set(state.models[2].children[0].positions, index, setObj);
      } else if (type === 'triangle') {
        Vue.set(state.models[2].children[1].positions, index, setObj);
      } else if (type === 'miniTriangle') {
        Vue.set(state.models[2].children[2].positions, index, setObj);
      } else if (type === 'lines') {
        Vue.set(state.models[3].children[0].positions, index, setObj);
      } else if (type === 'linesPuck') {
        Vue.set(state.models[3].children[0].puckPositions, index, setObj);
      } else if (type === 'linesSquared') {
        Vue.set(state.models[3].children[1].positions, index, setObj);
      } else if (type === 'linesSquaredPuck') {
        Vue.set(state.models[3].children[1].puckPositions, index, setObj);
      }
    },
    setAllPositions(state, { panel, canvas, hexagon, triangle, miniTriangle, lines, linesPuck, linesSquared, linesSquaredPuck }) {
      Vue.set(state.models[0], 'positions', panel);
      Vue.set(state.models[1], 'positions', canvas);
      Vue.set(state.models[2].children[0], 'positions', hexagon);
      Vue.set(state.models[2].children[1], 'positions', triangle);
      Vue.set(state.models[2].children[2], 'positions', miniTriangle);
      Vue.set(state.models[3].children[0], 'positions', lines);
      Vue.set(state.models[3].children[0], 'puckPositions', linesPuck);
      Vue.set(state.models[3].children[1], 'positions', linesSquared);
      Vue.set(state.models[3].children[1], 'puckPositions', linesSquaredPuck);
    },
    setControllerPosition(state, { x, y, rotation }) {
      state.controllerPosition = { ...state.controllerPosition, x, y, rotation };
    },
    selectPowerSupply(state, { model, index }) {
      let i = 0;
      if (model === 'NL29') {
        i = 1;
      } else if (model === 'NL42') {
        i = 2;
      }
      for (let j = 0; j < state.models[i].powerSupplies.length; j++) {
        if (j === index && !state.models[i].powerSupplies[j].selected) {
          const { title } = state.models[i];
          const { count, wattage } = state.models[i].powerSupplies[j];
          Vue.$toast({
            component: Toast,
            props: {
              title: `${title} PSU (${wattage}W)`,
              message: `${count} ${wattage}W ${title} ${count === 1 ? 'PSU was' : 'PSUs were'} added to your shopping list.`,
            },
          });

          if (count > state.powerSupplies.length) {
            for (let i = state.powerSupplies.length; i < count; i++) {
              state.powerSupplies.push({
                x: state.wallWidthPx - 50,
                y: state.wallHeightPx - 50,
                xCm: 0,
                yCm: 0,
                note: '',
              });
            }
          } else if (count < state.powerSupplies.length) {
            state.powerSupplies.splice(count, state.powerSupplies.length - count);
          }
          Vue.set(state, 'powerSupplies', state.powerSupplies);
        }
        Vue.set(state.models[i].powerSupplies[j], 'selected', j === index);
      }
    },
    setPowerSupplyPosition(state, { index, x, y, xCm, yCm }) {
      Vue.set(state.powerSupplies, index, { ...state.powerSupplies[index], x, y, xCm, yCm });
    },
    setPowerSupplyNote(state, { index, note }) {
      Vue.set(state.powerSupplies, index, { ...state.powerSupplies[index], note });
    },
    setShapesMountingToastShown(state) {
      state.shapesMountingToastShown = true;
      state.models[2].mountingPlatesSelected = true;
    },
    // only for Light Panels and Canvas
    toggleMountingPlatesSelected(state) {
      const i = Math.min(state.activeId, 2);
      if (i === 2) return;
      const { mountingPlates, mountingPlatesSelected, title } = state.models[i];

      const newValue = !mountingPlatesSelected;
      state.models[i].mountingPlatesSelected = newValue;

      if (newValue) {
        for (const plates of mountingPlates.filter((plates) => plates.count > 0)) {
          Vue.$toast({
            component: Toast,
            props: {
              title: `${title} ${plates.title}`,
              message: `${plates.count} ${title} ${plates.title} ${plates.count === 1 ? 'has' : 'have'} been added to your shopping list.`,
            },
          });
        }
      }
    },
    setMountingPlatesCount(state, { index, count }) {
      const i = Math.min(state.activeId, 2);
      const { count: oldCount } = state.models[i].mountingPlates[index];
      state.models[i].mountingPlates[index].count = count;

      const { mountingPlates, mountingPlatesSelected, title } = state.models[i];
      if (mountingPlatesSelected && oldCount < count) {
        const plates = mountingPlates[index];
        Vue.$toast({
          component: Toast,
          props: {
            title: `${title} ${plates.title}`,
            message: `${plates.count} ${title} ${plates.title} ${plates.count === 1 ? 'has' : 'have'} been added to your shopping list.`,
          },
        });
      }
    },
    setStep(state, step) {
      state.activeStep = step;
    },
    pushStateToUndoStack(state) {
      undoController.push(state);
      state.canUndo = undoController.canUndo();
      state.canRedo = undoController.canRedo();
    },
    undo(state) {
      if (state.canUndo) {
        const newState = undoController.undo();
        Object.assign(state, newState, { linkerView: state.linkerView });
        state.canUndo = undoController.canUndo();
        state.canRedo = undoController.canRedo();
      }
    },
    redo(state) {
      if (state.canRedo) {
        const newState = undoController.redo();
        Object.assign(state, newState, { linkerView: state.linkerView });
        state.canUndo = undoController.canUndo();
        state.canRedo = undoController.canRedo();
      }
    },
    toggleLinkerView(state) {
      state.linkerView = !state.linkerView;
    },
    setLayoutStringData(state, layoutStringData) {
      state.layoutStringData = layoutStringData;
    },
  },
  actions: {
    getDesignCode({ commit, state }) {
      const stateObject = JSON.parse(JSON.stringify(state));
      const isUpdate = state.designCode.length > 0;
      return new Promise((resolve, reject) => {
        getDesignCode(stateObject, isUpdate).then((code) => {
          commit('setDesignCode', code);
          commit('setSaved', true);
          const url = new URL(location.href);
          if (url.searchParams.get('design') !== code) {
            url.searchParams.set('design', code);
            history.pushState(null, document.title, url.toString());
          }
          stateObject.designCode = code;
          parent.postMessage(stateObject, '*');
          resolve(code);
        }).catch(reject);
      });
    },
    resetCount({ commit, state }, id) {
      const model = getModelById(state, id);
      const { expansionPacks } = model;
      commit('setCount', {
        id,
        smarterKitSelected: false,
        expansionPacks: expansionPacks.map((pack) => ({ ...pack, count: 0 })),
        count: 0,
      });
    },
    setStep({ commit, state, getters }, step) {
      commit('setStep', step);

      const index = Math.min(state.activeId, 2);
      const model = state.models[index];
      const { powerSupplies } = model;
      if (powerSupplies.every((ps) => !ps.selected)) {
        const firstPowerSupplyIndex = powerSupplies.findIndex((ps) => !state.isB2B || ps.b2b);
        commit('selectPowerSupply', {
          model: model.model,
          index: firstPowerSupplyIndex,
        });
      }

      if (step === 2 && !state.shapesMountingToastShown && getters.activeModel === 'NL42') {
        Vue.$toast({
          component: Toast,
          props: {
            title: 'Shapes Mounting Plates',
            message: `${getters.totalItemCount} Shapes mounting plates were added to your shopping list.`,
          },
        });
        commit('setShapesMountingToastShown');
      }
    },
  },
  plugins: [saveToLocalStorage, setSavedStatus, pushStateToUndoStack],
});

export default store;
