<template>
  <div class="container-right" ref="rightNav">
    <button v-for="(item, $index) in items" :key="'button' + $index" :class="'btn btn-nav' + (item.open ? ' active' : '')" @click="toggleItem($index)">
      <img v-show="item.open" :src="item.image" :alt="item.title" />
      <img v-show="!item.open" :src="item.image" :alt="item.title" />
      {{ item.title }}
    </button>
    <div style="flex: 1;"></div>
    <button class="btn btn-nav" @click="contactModalOpen = true">
      <img src="../assets/contact.svg" alt="Contact" />
      Contact
    </button>
    <div v-for="(item, $index) in items" :key="'item' + $index" v-show="item.open" class="item" :style="getItemStyle($index)">
      <button class="btn btn-close" :style="getCloseStyle($index)" @click="toggleItem($index)"><img src="../assets/closeRight.svg" alt="Close" /></button>
      <div v-show="item.title === 'Wall'">
        <div class="title">Wall Setting</div>
        <div class="body">
          <strong>Wall Name</strong><br />
          <input type="text" class="wall-name ga-wall-name" :value="wallName" @input="setName($event.target.value)" />
          <div class="dimensions">
            <DimensionInput prefix="W" :value="convertedWallWidth" :unit="measurementUnit" @change="setWidth($event)" />
            <DimensionInput prefix="H" :value="convertedWallHeight" :unit="measurementUnit" @change="setHeight($event)" />
          </div>
        </div>
      </div>
      <div v-show="item.title === 'Details'">
        <div class="title">Design Details</div>
        <div class="body">
          <strong>Measurement Unit</strong>
          <br />
          <MeasurementUnitToggle :metric="measurementUnit === 'metric'" @change="setMeasurementUnit($event)" />
          <br />
          <div v-show="activeModel === 'NL22' && numLightPanels > 0" class="spaced">
            <strong>Light Panels:</strong>
            <span>{{ numLightPanels }} panels</span>
          </div>
          <div v-show="activeModel === 'NL29' && numCanvases > 0" class="spaced">
            <strong>Canvas:</strong>
            <span>{{ numCanvases }} panels</span>
          </div>
          <div v-show="activeModel === 'NL42' && numShapes > 0">
            <strong>Shapes</strong>
          </div>
          <div v-show="activeModel === 'NL42' && numHexagons > 0" class="spaced">
            <span>Hexagons:</span>
            <span>{{ numHexagons }} panels</span>
          </div>
          <div v-show="activeModel === 'NL42' && numTriangles > 0" class="spaced">
            <span>Triangles:</span>
            <span>{{ numTriangles }} panels</span>
          </div>
          <div v-show="activeModel === 'NL42' && numMiniTriangles > 0" class="spaced">
            <span>Mini Triangles:</span>
            <span>{{ numMiniTriangles }} panels</span>
          </div>
          <div v-show="activeModel === 'NL59' && numLinesTotal > 0">
            <strong>Lines</strong>
          </div>
          <div v-show="activeModel === 'NL59' && numLines > 0" class="spaced">
            <span>Lines:</span>
            <span>{{ numLines }} panels</span>
          </div>
          <div v-show="activeModel === 'NL59' && numLinesSquared > 0" class="spaced">
            <span>Lines Squared:</span>
            <span>{{ numLinesSquared }} panels</span>
          </div>
          <div class="spaced">
            <strong>Design Size:</strong>
            <span>{{ designSizeText }}</span>
          </div>
          <div class="spaced">
            <strong>Wall Size:</strong>
            <span>{{ wallSizeText }}</span>
          </div>
        </div>
      </div>
      <div v-show="item.title === 'Layout'">
        <div class="title">Layout</div>
        <div class="body">
          <div class="rotate-buttons">
            <button class="btn btn-rotate ga-rotate" @click="setRotation(layoutRotation - 30)">
              <img src="../assets/rotateL.svg" alt="Rotate counterclockwise" />
            </button>
            <button class="btn btn-rotate ga-rotate" @click="setRotation(layoutRotation + 30)">
              <img src="../assets/rotateR.svg" alt="Rotate clockwise" />
            </button>
          </div>
          <div class="zoom-labels">
            <img class="ga-zoom" src="../assets/zoomoutdark.svg" alt="Zoom out" @click="setZoom(Math.max(wallZoom - 0.25, 0.25))" />
            <img class="ga-zoom" src="../assets/zoomindark.svg" alt="Zoom in" @click="setZoom(Math.min(wallZoom + 0.25, 2))" />
          </div>
          <input type="range" min="0.25" max="2" step="0.25" :value="wallZoom" @input="setZoom($event.target.valueAsNumber)" class="slider ga-zoom" />
        </div>
      </div>
      <div v-show="item.title === 'Legend'">
        <div class="title">Legend</div>
        <div class="body">
          <table class="mounting-legend">
            <tr v-show="activeModel === 'NL22'">
              <td class="img"><img src="../assets/lightpanelsmountdark.svg" alt="Mounting Plate" /></td>
              <td>Mounting Plate</td>
            </tr>
            <tr v-show="activeModel === 'NL29'">
              <td class="img"><img src="../assets/canvasmountdark.svg" alt="Mounting Plate" /></td>
              <td>Mounting Plate</td>
            </tr>
            <tr v-show="activeModel === 'NL42'">
              <td class="img"><img src="../assets/shapesmountdark.svg" alt="Mounting Plate" /></td>
              <td>Mounting Plate</td>
            </tr>
            <tr v-show="activeModel !== 'NL42'">
              <td class="img"><img src="../assets/canvastape.svg" alt="Mounting Tape" style="width: 10px;" /></td>
              <td>Mounting Tape</td>
            </tr>
            <tr v-show="activeModel === 'NL42'">
              <td class="img"><img src="../assets/tape.svg" alt="Mounting Tape" /></td>
              <td>Mounting Tape</td>
            </tr>
            <tr>
              <td class="img"><img src="../assets/screw.svg" alt="Mounting Screw" /></td>
              <td>Mounting Screw</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <Contact :open="contactModalOpen" @close="contactModalOpen = false" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

import wallImage from '../assets/wallsdark.svg';
import wallWhiteImage from '../assets/wallswhite.svg';
import detailsImage from '../assets/detailsdark.svg';
import detailsWhiteImage from '../assets/detailswhite.svg';
import layoutImage from '../assets/zoomindark.svg';
import layoutWhiteImage from '../assets/zoominwhite.svg';
import legendImage from '../assets/legenddark.svg';
import legendWhiteImage from '../assets/legendwhite.svg';

import Contact from '../modals/Contact.vue';
import DimensionInput from './DimensionInput.vue';
import MeasurementUnitToggle from './MeasurementUnitToggle.vue';

export default {
  name: 'RightNav',
  data() {
    return {
      allItems: [
        {
          title: 'Wall',
          image: wallImage,
          imageWhite: wallWhiteImage,
          open: false,
        },
        {
          title: 'Details',
          image: detailsImage,
          imageWhite: detailsWhiteImage,
          open: false,
        },
        {
          title: 'Layout',
          image: layoutImage,
          imageWhite: layoutWhiteImage,
          open: false,
        },
        {
          title: 'Legend',
          image: legendImage,
          imageWhite: legendWhiteImage,
          open: false,
        },
      ],
      contactModalOpen: false,
    };
  },
  mounted() {
    document.addEventListener('click', (e) => {
      if (!e.composedPath().includes(this.$refs.rightNav)) {
        for (let i = 0; i < this.allItems.length; i++) {
          this.$set(this.allItems[i], 'open', false);
        }
      }
    }); 
  },
  computed: {
    ...mapGetters([
      'activeModel',
      'numLightPanels',
      'numCanvases',
      'numHexagons',
      'numTriangles',
      'numMiniTriangles',
      'numShapes',
      'numLines',
      'numLinesSquared',
      'numLinesTotal',
      'wallWidth',
      'wallHeight',
    ]),
    ...mapState([
      'activeStep',
      'wallName',
      'measurementUnit',
      'designWidth',
      'designHeight',
      'layoutRotation',
      'wallZoom',
    ]),
    items() {
      switch (this.activeStep) {
        case 0: return this.allItems.slice(0, 3);
        case 2: return [this.allItems[1], this.allItems[3]];
      }
      return [this.allItems[1]];
    },
    convertedWallWidth() {
      const factor = this.measurementUnit === 'metric' ? 1 : 2.54;
      return Math.round(this.wallWidth / factor);
    },
    convertedWallHeight() {
      const factor = this.measurementUnit === 'metric' ? 1 : 2.54;
      return Math.round(this.wallHeight / factor);
    },
    designSizeText() {
      const unit = this.measurementUnit === 'metric' ? 'cm' : 'in';
      // design dimensions in the store are always in cm, so convert to in if necessary
      const factor = this.measurementUnit === 'metric' ? 1 : 2.54;
      const width = this.designWidth / factor;
      const height = this.designHeight / factor;
      return `${Math.round(width)} ${unit} x ${Math.round(height)} ${unit}`;
    },
    wallSizeText() {
      const unit = this.measurementUnit === 'metric' ? 'cm' : 'in';
      // wall dimensions in the store are always in cm, so convert to in if necessary
      return `${this.convertedWallWidth} ${unit} x ${this.convertedWallHeight} ${unit}`;
    },
  },
  methods: {
    ...mapMutations([
      'setName',
      'setMeasurementUnit',
      'setWidth',
      'setHeight',
      'setZoom',
      'setRotation',
    ]),
    toggleItem(index) {
      for (let i = 0; i < this.items.length; i++) {
        if (i !== index) {
          this.$set(this.items[i], 'open', false);
        }
      }
      this.$set(this.items[index], 'open', !this.items[index].open);
    },
    getItemStyle(index) {
      const top = index === 0 ? 30 : index === 1 ? 110 : 190;
      return { top: `${top}px` };
    },
    getCloseStyle(index) {
      const top = index === 0 ? 118 : index === 1 ? 198 : 278;
      return { top: `${top}px` };
    },
  },
  components: {
    Contact,
    DimensionInput,
    MeasurementUnitToggle,
  },
};
</script>

<style scoped>
.container-right {
  background-color: white;
  box-shadow: none;
  border: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 65px);
  padding-top: 30px;
  position: relative;
  width: 100px;
}

.item {
  background-color: white;
  border: 1px solid #CCC;
  border-radius: 8px;
  box-shadow: 0 0 5px #CCC;
  position: absolute;
  right: 100px;
  width: 275px;
  z-index: 3;
}

.btn-close {
  position: fixed;
  right: 350px;
}

.btn.btn-nav:hover, .btn.btn-nav.active:hover {
  background-color: #F1F2F2;
}

.item .title {
  border-bottom: 1px solid #F1F2F2;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 15px;
  text-align: center;
}

.item .body {
  font-size: 14px;
  padding: 10px 20px 15px;
}

.item .body input {
  margin: 12px 0;
  width: 100%;
}

.wall-name {
  background-color: #F1F2F2;
}

.dimensions {
  display: flex;
}

.dimensions .wrapper {
  border-radius: 5px;
}

.dimensions div:nth-child(1) {
  margin-right: 5px;
}

.dimensions div:nth-child(2) {
  margin-left: 5px;
}

.item .spaced {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  width: 100%;
}

.btn-close img {
    width: 35px;
}

.rotate-buttons {
  display: flex;
  justify-content: space-between;
  margin: 1em 0;
  padding-bottom: 30px;
}

.rotate-buttons .btn-rotate {
  background-color: #F1F2F2;
  border: 2px solid #F1F2F2;
  border-radius: 5px;
  padding: 8px 43px;
}

.rotate-buttons .btn-rotate:hover {
  border: 2px solid #3A4C55;
}

.rotate-buttons .btn-rotate img {
  margin-top: 1px;
  width: 15px;
}

.zoom-labels {
  display: flex;
  justify-content: space-between;
}

.zoom-labels img {
  cursor: pointer;
  margin-bottom: 5px;
  width: 15px;
}

input:hover, input:focus {
  background-color: rgba(241,242,242,0.5);
  border-color: rgba(241,242,242,0.25);
}

/* Slider CSS from http://danielstern.ca/range.css */
.item .body input[type=range] {
  margin-bottom: 2em;
}

input[type=range]{
  -webkit-appearance: none;
  cursor: pointer;
  background: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  background-color: #F1F2F2;
  border: none;
  border-radius: 3px;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  background-color: #F1F2F2;
  border: none;
  border-radius: 3px;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: #3A4C55;
  border: 4px solid white;
  margin-top: -8px;
  box-shadow: 0px 5px 15px -5px rgba(0,0,0,0.5);
  -webkit-box-shadow: 0px 5px 15px -5px rgba(0,0,0,0.5);
}

input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: #3A4C55;
  border: 4px solid white;
  margin-top: -8px;
  box-shadow: 0px 5px 15px -5px rgba(0,0,0,0.5);
  -webkit-box-shadow: 0px 5px 15px -5px rgba(0,0,0,0.5);
}

input[type=range]:focus {
  outline: none;
}

input[type=range]:hover {
  background: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background-color: #DADEE1;
}

input[type=range]::-ms-fill-lower {
  background-color: #3EAF29;
}
input[type=range]::-ms-fill-upper {
  background-color: #f1f2f2;
}

/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align:auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type=range] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}

.slider {
  border: none;
  padding: 0;
  width: 100%;
}

.mounting-legend {
  width: 100%;
}

.mounting-legend td.img {
  font-size: 14px;
  padding: 5px;
  text-align: center;
  width: 20%;
}

.mounting-legend td.img img {
  width: 20px;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .container-right {
    border: none;
    box-shadow: none;
    width: 80px;
  }

  .btn {
    font-size: 12px;
  }

  .container-right > .btn {
    height: 80px;
    padding: 17px 0;
  }

  .item {
    border-radius: 5px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    -webkit-box-shadow: -5px 0px 15px -5px rgba(0,0,0,0.1);
    box-shadow: -5px 0px 15px -5px rgba(0,0,0,0.1);
    border: 1px solid #F1F2F2;
    right: 80px;
  }

  .item .body strong {
    font-size: 12px;
  }

  input {
    padding: 10px 0 10px 10px;
  }

  .btn-close {
    right: 340px;
  }

  .wrapper > button {
    font-weight: bold;
  }

  .slider {
    padding-bottom: 1em;
  }

  .item .body {
    font-size: 12px;
  }
}

@media only screen and (min-device-width: 1367px) {
  .container-right {
    border: none;
    box-shadow: none;
    width: 80px;
  }

  .btn.btn-nav {
    height: 80.5px;
    padding: 15px 0;
  }

  .item {
    border-radius: 5px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    -webkit-box-shadow: -5px 0px 15px -5px rgba(0,0,0,0.1);
    box-shadow: -5px 0px 15px -5px rgba(0,0,0,0.1);
    border: 1px solid #F1F2F2;
    right: 80px;
    width: 275px;
  }

  .item .title {
    border-bottom: 1px solid #F1F2F2;
    font-size: 14px;
  }

  .item .body strong {
    font-size: 14px;
  }

  .btn-close {
    right: 340px;
  }
}
</style>
