function convertState(state) {
  if (state.version === 1) {
    changeIsRotatedToRotation(state);
    state.version = 2;
  }
}

function changeIsRotatedToRotation(state) {
  const positions = [
    ...state.models[0].positions,
    ...state.models[1].positions,
    ...state.models[2].children[0].positions,
    ...state.models[2].children[1].positions,
    ...state.models[2].children[2].positions,
  ];
  for (const position of positions) {
    position.rotation = position.isRotated ? 180 : 0;
    delete position.isRotated;
  }
}

export default convertState;
