<template>
  <div class="wrapper">
    <button :class="leftActive ? 'active' : ''" @click="toggle">{{ left }}</button>
    <button :class="leftActive ? '' : 'active'" @click="toggle">{{ right }}</button>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    leftActive: {
      type: Boolean,
      default() {
        return true;
      },
    },
    left: {
      type: String,
      required: true,
    },
    right: {
      type: String,
      required: true,
    },
  },
  methods: {
    toggle() {
      this.$emit('change', !this.leftActive);
    },
  },
};
</script>

<style scoped>
.wrapper {
  background-color: #F0F3F2;
  border-radius: 25px;
  display: flex;
  font-size: 14px;
  margin: 10px 0;
  padding: 2px;
}

.wrapper > button {
  background-color: #F0F3F2;
  border: none;
  border-radius: 25px;
  color: #C1C7C9;
  cursor: pointer;
  font-size: 12px;
  padding: 8px 22px;
  text-align: center;
  text-transform: uppercase;
  width: 50%;
}

.wrapper > button:focus {
  outline: none;
}

.wrapper > button.active {
  background-color: #3A4C55;
  color: white;
}
</style>
