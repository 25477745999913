<template>
  <div>
    <header>
      <div class="left">
        <div class="logo">
          <img src="../assets/nanoleaf.png" alt="Nanoleaf" />
          <span>Beta</span>
        </div>
        <button class="btn btn-start-new ga-start-new" @click="startNewModalOpen = true">Start New</button>
      </div>
      <div class="middle">
        <button v-show="activeStep === 0" class="btn ga-undo" :disabled="!canUndo" @click="handleUndo">
          <img v-show="canUndo" src="../assets/undo-active.svg" />
          <img v-show="!canUndo" src="../assets/undo-disabled.svg" />
        </button>
        <button v-show="activeStep === 0" class="btn ga-redo" :disabled="!canRedo" @click="handleRedo">
          <img v-show="canRedo" src="../assets/redo-active.svg" />
          <img v-show="!canRedo" src="../assets/redo-disabled.svg" />
        </button>
        <span v-show="activeStep === 0" class="separator"></span>
        <strong v-show="activeStep <= 1" style="font-size: 14px;">Linker View</strong>
        <div v-show="activeStep <= 1 && !linkerView" class="linker-view-toggle off" @click="toggleLinkerView">
          <div class="circle"></div>
        </div>
        <div v-show="activeStep <= 1 && linkerView" class="linker-view-toggle on" @click="toggleLinkerView">
          <div class="circle"></div>
        </div>
      </div>
      <div class="right">
        <button class="btn" @click="loadDesignModalOpen = true">Load Work</button>
        <span class="separator"></span>
        <button class="btn btn-save ga-save" @click="saveAndShareModalOpen = true">
          <span v-show="saved" class="saved-message saved">Saved!</span>
          <span v-show="!saved" class="saved-message not-saved">Not Saved!</span>
          <img src="../assets/save.svg" alt="Save" />
        </button>
      </div>
    </header>
    <LoadDesign :open="loadDesignModalOpen" @close="loadDesignModalOpen = false" />
    <SaveAndShare :open="saveAndShareModalOpen" @close="saveAndShareModalOpen = false" />
    <StartNew :open="startNewModalOpen" @cancel="startNewModalOpen = false" @ok="startNew" />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import LoadDesign from '../modals/LoadDesign.vue';
import SaveAndShare from '../modals/SaveAndShare.vue';
import StartNew from '../modals/StartNew.vue';

import secureStorage from '../secureStorage';

export default {
  name: 'Header',
  mounted() {
    document.addEventListener('keydown', (e) => {
      if (e.target === document.body) {
        e.preventDefault();
        if (e.ctrlKey || e.metaKey) {
          if (e.key === 'z') {
            this.handleUndo();
          } else if (e.key === 'y') {
            this.handleRedo();
          }
        }
      }
    });
  },
  data() {
    return {
      loadDesignModalOpen: false,
      saveAndShareModalOpen: false,
      startNewModalOpen: false,
    };
  },
  computed: {
    ...mapState([
      'activeStep',
      'canUndo',
      'canRedo',
      'linkerView',
      'saved',
    ]),
  },
  methods: {
    ...mapMutations([
      'undo',
      'redo',
      'toggleLinkerView',
    ]),
    startNew() {
      secureStorage.deleteState();
      const url = new URL(location.href);
      url.searchParams.delete('design');
      location.replace(url.toString());
    },
    async handleUndo() {
      this.undo();
      await this.$nextTick();
      this.$root.$emit('undo-redo');
    },
    async handleRedo() {
      this.redo();
      await this.$nextTick();
      this.$root.$emit('undo-redo');
    },
  },
  components: {
    LoadDesign,
    SaveAndShare,
    StartNew,
  },
};
</script>

<style scoped>
header {
  border-bottom: 1px solid #F1F2F2;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  height: 65px;
  padding: 15px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.left {
  display: flex;
  justify-content: space-between;
  width: 300px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100px;
}

.logo img {
  width: 100px;
}

.btn.btn-start-new {
  background-color: #F0F3F2;
  border-radius: 15px;
  padding: 15px 20px;
  text-transform: uppercase;
}

.middle {
  display: flex;
  align-items: center;
  height: 35px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  font-weight: bold;
  padding: 5px 15px;
  text-transform: none;
}

.btn:disabled {
  cursor: default;
}

.btn:hover:not(:disabled) {
  background-color: #F0F3F2;
}

.separator {
  align-self: stretch;
  border-left: 1px solid #F1F2F2;
  margin: 0 20px;
}

.linker-view-toggle {
  background-color: #DADEE1;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 10px;
  height: 30px;
  width: 60px;
}

.linker-view-toggle .circle {
  background-color: #F8F8F8;
  border-radius: 50%;
  height: 28px;
  width: 28px;
}

.linker-view-toggle.off .circle {
  margin-left: 1px;
}

.linker-view-toggle.on {
  background-color: #3B4C55;
  justify-content: flex-end;
}

.linker-view-toggle.on .circle {
  margin-right: 1px;
}

.right {
  display: flex;
  justify-content: flex-end;
  width: 300px;
}

.btn-save {
  width: 150px;
}

.btn-save img {
  margin-left: 15px;
  width: 22px;
}

.saved-message {
  font-size: 14px;
  font-weight: normal;
  padding: 15px 0;
}

.saved-message.not-saved {
  color: #FF4544;
}

.saved-message.saved {
  color: #3EAF29;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .saved-message {
    font-size: 12px;
  }
}
</style>
