<template>
  <div>
    <div class="box">
      <BoxCount :model-name="modelName" :size="size" :count="count" />
    </div>
    <div class="box">
      <button :class="changeButtonClasses" @click="change(-1)">-</button>
      <div class="box-middle">
        <div class="total-count">{{ totalCount }}</div>
        <div class="subtitle">{{ subtitle }}</div>
      </div>
      <button :class="changeButtonClasses" @click="change(1)">+</button>
    </div>
  </div>
</template>

<script>
import BoxCount from './BoxCount.vue';

export default {
  name: 'AddMultiple',
  props: {
    modelName: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0;
      },
    },
    size: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0;
      },
    },
  },
  computed: {
    changeButtonClasses() {
      if (this.modelName) {
        const model = this.modelName.toLowerCase().replace(/\s/g, '-');
        return `btn btn-open btn-multiple ga-change-${model}-count`;
      }
      return 'btn btn-open btn-multiple';
    },
    totalCount() {
      return this.count * this.size;
    },
  },
  methods: {
    change(n) {
      this.$emit('change', Math.max(this.count + n, 0));
    },
  },
  components: {
    BoxCount,
  },
};
</script>

<style scoped>
.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
  padding: 0 20px;
}

.box:nth-child(odd) {
  margin: 22px 0 15px;
}

.box:nth-child(1) {
  margin-top: 0;
}

.title {
  font-size: 14px;
  font-weight: bold;
}

.btn.btn-open.btn-multiple {
  border-radius: 3px;
  box-shadow: 0 0 13px -5px rgba(0, 0, 0, 0.25);
  font-size: 25px;
  height: 35px;
  padding: 0;
  width: 35px;
}

.btn.btn-open.btn-multiple:hover {
  background-color: #3A4C55;
  color: white;
}

.total-count {
  flex: 1;
  font-weight: bold;
  margin: 0 10px;
  text-align: center;
}

.subtitle {
  color: #AAA;
  font-size: 12px;
  text-align: center;
}
</style>
