<template>
  <div id="pdf" class="pdf-wrapper">
    <div class="left">
      <img src="../assets/nanoleaf.png" alt="Nanoleaf" class="logo" />
      <div class="design" ref="design"></div>
    </div>
    <div class="right">
      <div>
        <p class="design-code">Design code: <strong>{{ designCode }}</strong></p>
        <Summary />
      </div>
      <div class="right-bottom">
        <p>
          <span><strong>Design size:</strong> {{ designSizeText }}</span>
          <span><strong>Wall size:</strong> {{ wallSizeText }}</span>
        </p>
        <div v-show="showPowerSupply">
          <p v-for="(powerSupply, $index) in powerSupplies" :key="'psuDistanceText_' + $index">
            <span><strong>PSU({{ $index + 1 }}) distance to wall edge:</strong> {{ getPsuDistanceText($index) }}</span>
          </p>
        </div>
        <div v-show="showPowerSupply">
          <p v-for="(powerSupply, $index) in powerSupplies" :key="'psuDistanceText_' + $index">
            <span><strong>PSU({{ $index + 1 }}) message:</strong> {{ powerSupplies[$index].note || 'No message' }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import domtoimage from 'dom-to-image';
import { mapActions, mapMutations, mapState } from 'vuex';

import Summary from './Summary.vue';

export default {
  name: 'Pdf',
  data() {
    return {
      designCode: 'loading...',
    };
  },
  computed: {
    ...mapState([
      'activeStep',
      'measurementUnit',
      'designHeight',
      'designWidth',
      'wallHeight',
      'wallWidth',
      'powerSupplies',
      'wallHeightPx',
      'wallWidthPx',
      'layoutRotation',
    ]),
    unit() {
      return this.measurementUnit === 'metric' ? 'cm' : 'in';
    },
    factor() {
      return this.measurementUnit === 'metric' ? 1 : 2.54;
    },
    designSizeText() {
      // design dimensions in the store are always in cm, so convert to in if necessary
      const width = this.designWidth / this.factor;
      const height = this.designHeight / this.factor;
      return `${Math.round(width)} ${this.unit} x ${Math.round(height)} ${this.unit}`;
    },
    wallSizeText() {
      return `${Math.round(this.wallWidth)} ${this.unit} x ${Math.round(this.wallHeight)} ${this.unit}`;
    },
    showPowerSupply() {
      // do not show power supply if user skipped power step
      return this.powerSupplies.every(({ x, y }) => Number.isFinite(x) && Number.isFinite(y));
    },
  },
  watch: {
    async activeStep(step) {
      if (step === 3) {
        this.setPdfReady(false);
        try {
          this.designCode = await this.getDesignCode();
        } catch {
          this.designCode = 'error';
        }
        await this.addDesign();
        this.setPdfReady(true);
      }
    },
  },
  methods: {
    ...mapMutations([
      'setPdfReady',
    ]),
    ...mapActions([
      'getDesignCode',
    ]),
    sanitizeWall(wall) {
      wall.setAttribute('id', 'pdf-wall');
      wall.style.cssText = `background: none; height: ${this.wallHeightPx}px; width: ${this.wallWidthPx}px; transform-origin: left top;`;

      for (const drr of wall.getElementsByClassName('drr')) {
        drr.style.cursor = 'default';
        // remove red overflow outlines
        const borders = drr.getElementsByClassName('border');
        if (borders.length > 0) {
          borders[0].style.stroke = '#F1F2F2';
        }
      }

      for (const powerBorder of wall.getElementsByClassName('power-border')) {
        powerBorder.style.display = 'none';
      }
      for (const powerNote of wall.getElementsByClassName('power-note')) {
        powerNote.style.display = 'none';
      }

      for (const powerSupply of wall.getElementsByClassName('power-supply')) {
        powerSupply.parentNode.style.display = this.showPowerSupply ? 'block' : 'none';
      }

      return wall;
    },
    addDesign() {
      return new Promise((resolve, reject) => {
        const { design } = this.$refs;
        // remove old design
        if (design.childNodes[0]) {
          design.childNodes[0].remove();
        }
        design.appendChild(this.sanitizeWall(document.getElementById('wall').cloneNode(true)));

        // PDF rotation does not support transform origin convert to PNG
        const pdfWall = document.getElementById('pdf-wall');
        domtoimage.toPng(pdfWall).then((designImgUrl) => {
          design.removeChild(pdfWall);
          const pdfWallImg = new Image();
          pdfWallImg.onload = () => {
            if (this.wallWidthPx >= this.wallHeightPx) {
              pdfWallImg.style.width = `${design.clientWidth}px`;

              const scaledImgHeight = pdfWallImg.height * design.clientWidth / pdfWallImg.width;
              pdfWallImg.style.transform = `translateY(${0.5 * (design.clientHeight - scaledImgHeight)}px)`;
            } else {
              pdfWallImg.style.height = `${design.clientHeight}px`;

              const scaledImgWidth = pdfWallImg.width * design.clientHeight / pdfWallImg.height;
              // - 20px because it's not centred for some reason
              pdfWallImg.style.transform = `translateX(${0.5 * (design.clientWidth - scaledImgWidth) - 20}px)`;
            }
            design.appendChild(pdfWallImg);
            resolve();
          };
          pdfWallImg.src = designImgUrl;
        }).catch(reject);
      });
    },
    getPsuDistanceText(index) {
      // psu distance in the store is always in cm, so convert to in if necessary
      const { xCm, yCm } = this.powerSupplies[index];
      const width = xCm / this.factor;
      const height = yCm / this.factor;
      return `${Math.round(width)} ${this.unit} to edge, ${Math.round(height)} ${this.unit} to floor`;
    },
  },
  components: {
    Summary,
  },
};
</script>

<style scoped>
.pdf-wrapper {
  display: flex;
  height: 8.5in;
  width: 11in;
  position: absolute;
  top: -9999px;
}

.pdf-wrapper > div {
  box-sizing: border-box;
  padding: 30px;
  width: 50%;
}

.left {
  background-color: white;
}

.logo {
  margin-left: 15px;
  width: 150px;
}

.design {
  background-color: #F0F3F2;
  border-radius: 8px;
  height: calc(100% - 60px);
  margin: 15px;
}

.right {
  background-color: #F0F3F2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.design-code {
  font-size: 14px;
  margin-bottom: 50px;
  text-align: right;
}

/* Fix alignment in PDF */
.design-code strong {
  position: relative;
  top: -1px;
}

/* Override Summary style */
.right >>> .subtotal {
  background-color: #DDEADD !important;
}

.right-bottom p span {
  display: inline-block;
  font-size: 12px;
  margin-bottom: 5px;
  margin-right: 20px;
}
</style>
