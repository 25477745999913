<template>
  <transition name="modal">
    <div class="mask" v-show="open" ref="mask" @click="close">
      <div class="container" :style="modalContainerStyle" @click.stop>
        <header :style="modalHeaderStyle">
          <button class="btn close-button" @click="$emit('close')">
            <img src="../assets/close.svg" alt="Close" />
          </button>
          <h3>{{ title }}</h3>
        </header>
        <slot />
      </div>
    </div>
  </transition> 
</template>

<script>
export default {
  name: 'ModalBase',
  props: {
    title: {
      type: String,
    },
    width: {
      type: String,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    document.addEventListener('keydown', ({ keyCode }) => {
      if (this.open && keyCode === 27) {
        this.close();
      }
    });

    this.$refs.mask.addEventListener('dblclick', (e) => {
      if (this.open && e.target === this.$refs.mask) {
        this.close();
      }
    });
  },
  computed: {
    modalContainerStyle() {
      return { width: this.width };
    },
    modalHeaderStyle() {
      if (typeof this.title !== 'undefined') {
        return { padding: '35px 30px 20px 30px' };
      }
      return {};
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>
/* Modal styles from https://vuejs.org/v2/examples/modal.html */
.mask {
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: opacity .3s ease;
  z-index: 4;
}

h3 {
  font-size:32px;
}

.container {
  background-color: white;
  border-radius: 10px;
  font-size: 14px;
  margin: 40px auto 0;
  transition: all .3s ease;
  width: 65%;
}

header {
  font-size: 22px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 30px;
  right: 30px;
}

.close-button img {
  width: 25px;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.container >>> .modal-body {
  line-height: 1.5;
  padding: 0 30px;
}

.container >>> .tabs {
  border-bottom: 1px solid #CCC;
  font-size: 12px;
  font-weight: bold;
}

.container >>> .tabs ul {
  display: flex;
}

.container >>> .tabs ul li {
  cursor: pointer;
  list-style-type: none;
  margin-right: 25px;
  padding-bottom: 5px;
}

.container >>> .tabs ul li.active {
  border-bottom: 2px solid black;
}

.container >>> main {
  display: flex;
  align-items: center;
}

.container >>> main > div {
  flex: 1;
  padding: 20px;
  text-align: center;
}

.container >>> main .description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 250px;
}

.container >>> main .description .title {
  font-size: 16px;
  font-weight: bold;
}

.container >>> main .description p {
  font-size: 14px;
  margin-bottom: 20px;
}

.container >>> main .image img {
  border-radius: 10px;
  width: 350px;
}

.container >>> main .description .buttons button {
  background-color: #F8F8F8;
  border-radius: 50%;
  margin: 10px;
  height: 40px;
  width: 40px;
}

.container >>> main .description .buttons button img {
  width: 23px;
}

.container >>> .modal-actions {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
}

.container >>> .modal-actions .btn {
  border-radius: 25px;
  font-size: 12px;
  font-weight: bold;
  padding: 13px 30px;
  text-transform: uppercase;
}

.container >>> .modal-actions .btn.primary {
  background-color: #3EAF29;
  color: white;
}

.container >>> .modal-actions .btn.danger {
  background-color: #FF4544;
  color: white;
}

@media only screen and (min-device-width: 1280px) {
  .modal-body > .wrapper {
    height: 70vh;
  }
}

@media only screen and (min-device-width: 1367px) {
  .right {
    background-position: 50% 65%;
  }

  .modal-body > .wrapper {
    height: 70vh;
  }
}

@media only screen and (min-device-width: 1600px) {
  .container {
    width: 50%;
  }
}

@media only screen and (min-device-width: 1920px) {
  .container {
    width: 45%;
  }
}
</style>
