<template>
  <div class="wrapper">
    <div class="total-text">Total {{ name }}</div>
    <div class="total-right">
      <div class="total-number">{{ count }}</div>
      <button v-show="resettable" :class="resetButtonClasses" @click="reset">
        <img src="../assets/close.svg" alt="Close" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PanelCount',
  props: {
    name: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0;
      },
    },
    resettable: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  computed: {
    resetButtonClasses() {
      const model = this.name.toLowerCase().replace(/\s/g, '-');
      return `btn btn-total-reset ga-change-${model}-count`;
    },
  },
  methods: {
    reset() {
      this.$emit('reset');
    },
  },
}
</script>

<style scoped>
.wrapper {
  background-color: #DADEE0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: lighter;
  margin: 15px 0;
  padding: 5px 20px;
}

.total-text {
  font-size: 14px;
}

.total-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.total-number {
  margin-right: 10px;
}

.btn-total-reset {
  padding: 0;
}

.btn-total-reset img {
  margin-top: 3px;
  width: 15px;
}
</style>
