<template>
  <ModalBase title="Save and Share" width="350px" :open="open" @close="$emit('close')">
    <div class="modal-body">
      <p class="description">Busy life? Come back where you left off by using the code below.</p>
      <p v-show="message.text.length > 0" :class="'message ' + message.type">{{ message.text }}</p>
      <div class="input link-container">
        <input type="text" v-model="link" ref="link" />
        <button class="btn" @click="copyLink">{{ copyLinkText }}</button>
      </div>
      <p class="link-message">Link expires in <strong>seven days</strong>.</p>
      <label class="email-message">Email yourself the design code</label>
      <div class="input email-container">
        <input type="email" placeholder="Email" v-model="email" />
        <button class="btn ga-email" :disabled="sendingLink || link === 'loading...'" @click="emailLink">Send</button>
      </div>
      <div class="checkbox-container">
        <input type="checkbox" v-model="optin" />
        <label>Opt in for the latest news and discounts</label>
      </div>
    </div>
  </ModalBase>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import ModalBase from './ModalBase.vue'

export default {
  name: 'SaveAndShare',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      message: { text: '', type: '' },
      copyLinkText: 'Copy',
      link: 'loading...',
      email: '',
      sendingLink: false,
      optin: false,
    };
  },
  watch: {
    open(isOpen) {
      if (isOpen) {
        this.saveAndShare();
      } else {
        // Wait before resetting tab so that it does not reset while the close animation is running
        setTimeout(() => {
          this.message = { text: '', type: '' };
          this.copyLinkText = 'Copy';
          this.link = 'loading...';
          this.email = '';
        }, 500);
      }
    },
  },
  computed: {
    ...mapState([
      'isB2B',
    ]),
  },
  methods: {
    ...mapActions([
      'getDesignCode',
    ]),
    copyLink() {
      this.$refs.link.focus();
      this.$refs.link.select();
      document.execCommand('copy');
      this.copyLinkText = 'Copied';
    },
    async saveAndShare() {
      try {
        const code = await this.getDesignCode();
        this.message = { text: '', type: '' };
        const url = new URL(location.href);
        url.searchParams.set('design', code);
        if (this.isB2B) {
          url.searchParams.set('b2b', 'true');
        }
        this.link = url.toString();
      } catch (err) {
        if (err.status === 429) {
          this.message = { text: 'Sorry, please wait a few seconds before saving your design again.', type: 'error' };
        } else {
          this.message = { text: 'Sorry, there was an error saving your design.', type: 'error' };
          this.link = '';
        }
      }
    },
    async emailLink() {
      this.sendingLink = true;
      this.message = { text: 'Sending link...', type: '' };

      const data = {
        email: this.email,
        link: this.link,
        code: new URL(this.link).searchParams.get('design'),
        optin: this.optin,
      };
      try {
        const res = await fetch(process.env.VUE_APP_EMAIL_URL, {
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
        });
        if (res.status === 200) {
          this.message = { text: 'Email sent successfully.', type: 'success' };
        } else if (res.status === 429) {
          this.message = { text: 'Sorry, please wait a few seconds before sending another link.', type: 'error' };
        } else {
          this.message = { text: 'Sorry, there was an error sending the link.', type: 'error' };
        }
      } catch {
        this.message = { text: 'Sorry, there was an error sending the link.', type: 'error' };
      } finally {
        this.sendingLink = false;
      }
    },
  },
  components: {
    ModalBase,
  },
};
</script>

<style scoped>
.description {
  font-size: 12px;
  margin-top: -15px;
  width: 75%;
}

.message {
  font-weight: bold;
  margin-top: 10px;

}

.error {
  color: red;
}

.success {
  color: #3EAF29;
}

.input {
  display: flex;
  margin-top: 20px;
  margin-bottom: 5px;
}

.input input {
  border-radius: 8px 0 0 8px;
  width: 80%;
}

.input .btn {
  border-radius: 0 8px 8px 0;
  color: white;
  font-size: 10px;
  font-weight: bold;
  padding: 12px 20px;
  text-transform: uppercase;
  width: 75px;
}

.link-container .btn {
  background-color: #3B4C55;
}

.link-message {
  font-size: 12px;
  margin-bottom: 40px;
}

.email-message {
  color: #49B235;
  font-weight: bold;
  margin-bottom: -10px;
  position: relative;
  top: 8px;
}

.email-container .btn {
  background-color: #49B235;
}

.checkbox-container {
  display: flex;
  align-items: center;
  padding-bottom: 35px;
  position: relative;
  top: 5px;
}

.checkbox-container input {
  margin-right: 10px;
}

.checkbox-container label {
  font-size: 12px;
}
</style>
