<template>
  <ModalBase title="Too Narrow" width="400px" :open="open" @close="$emit('close')">
    <div class="modal-body">
      <p>Oops! The layout assistant was designed to work best in landscape orientation on screens larger than 1000px. Give that a try. Trust us, you'll like it better! :),</p>
      <div class="modal-actions">
        <button class="btn primary" @click="$emit('close')">Got It</button>
      </div>
    </div>
  </ModalBase>
</template>

<script>
import ModalBase from './ModalBase.vue';

export default {
  name: 'TooNarrow',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ModalBase,
  },
};
</script>
