import lightpanelsmountplate from '../assets/modals/NL22/lightpanelsmountplate.jpg';
import lightpanelsmounttape from '../assets/modals/NL22/lightpanelsmounttape.jpg';

import canvasmountplate from '../assets/modals/NL29/canvasmountplate.jpg';
import canvasmounttape from '../assets/modals/NL29/canvasmounttape.jpg';

import shapesmount1 from '../assets/modals/NL42/shapesmount1.jpg';
import shapesmount2 from '../assets/modals/NL42/shapesmount2.jpg';
import minimount1 from '../assets/modals/NL42/minimount1.jpg';
import minimount2 from '../assets/modals/NL42/minimount2.jpg';

export default {
  NL22: [
    {
      title: 'Mounting Plate',
      description: 'Designed for mounting your Nanoleaf Light Panels to ceilings, uneven/porous surfaces, or anywhere that foam tape is unsuitable. Learn more at <a href="https://www.nanoleaf.me/en-US/products/nanoleaf-light-panels" target="_blank">nanoleaf.me/rhythm</a>. Only compatible with the Nanoleaf Light Panels product line.',
      images: [lightpanelsmountplate],
    },
    {
      title: 'Mounting Tape',
      description: 'Each Smarter Kit includes an adhesive double-sided Mounting Tape for each panel that allows you to easily attach panels to the wall with no drilling required. Note: We do not sell additional tape separately on our shop, but you can find the same 3M Command Tape at any local hardware or craft store.',
      images: [lightpanelsmounttape],
    },
  ],
  NL29: [
    {
      title: 'Mounting Plate',
      description: 'Designed for mounting your Nanoleaf Canvas to ceilings, uneven/porous surfaces, or anywhere that foam tape is unsuitable. One frame of the Screw Mount Kit is used to mount one light square. Each kit includes a suction cup removal tool for removing the light squares from the plates. Learn more at <a href="https://www.nanoleaf.me/en-US/products/nanoleaf-canvas" target="_blank">nanoleaf.me/canvas</a>. Only compatible with the Nanoleaf Canvas product line.',
      images: [canvasmountplate],
    },
    {
      title: 'Mounting Tape',
      description: 'Each Smarter Kit includes an adhesive double-sided Mounting Tape for each panel that allows you to easily attach panels to the wall with no drilling required. Note: We do not sell additional tape separately on our shop, but you can find the same 3M Command Tape at any local hardware or craft store.',
      images: [canvasmounttape],
    },
  ],
  NL42: [
    {
      title: 'Mounting Plate',
      description: 'Replacement Mounting Tape for your Nanoleaf Shapes product. Choose mini tape for your Nanoleaf Shapes Mini Triangles, or standard tape for your Nanoleaf Shapes Triangles or Hexagons. Learn more at <a href="https://www.nanoleaf.me/en-US/products/nanoleaf-shapes" target="_blank">nanoleaf.me/shapes</a>. Only compatible with the Nanoleaf Shapes product line.',
      images: [shapesmount1, shapesmount1, minimount1, minimount2],
    },
    {
      title: 'Mounting Screw',
      description: 'Push the screws though two holes in the Mounting Plate and install them securely to your surface.',
      images: [shapesmount1, shapesmount2, minimount1, minimount2],
    },
  ],
};
