<template>
  <div>
    <div v-show="step < 2" class="column">
      <div v-show="step === 0" class="input-wrapper">
        <label>First Name</label>
        <input type="text" v-model="firstName" required />
      </div>
      <div v-show="step === 0" class="input-wrapper">
        <label>Last Name</label>
        <input type="text" v-model="lastName" required />
      </div>
      <div v-show="step === 0" class="input-wrapper">
        <label>Email</label>
        <input type="email" v-model="email" required />
      </div>
      <div v-show="step === 1" class="input-wrapper">
        <label>Country</label>
        <input type="text" v-model="country" required />
      </div>
      <div v-show="step === 1" class="input-wrapper">
        <label>Message/Request</label>
        <textarea v-model="message" />
      </div>
      <div v-show="step === 1" class="checkbox">
        <input type="checkbox" v-model="optin" />
        Opt-in
      </div>
      <div v-show="step === 1" class="optin-message">
        I consent to recieve marketing communication from Nanoleaf. You may unsubscribe at any time using the link in our newsletter. We never sell your email address to third parties.
      </div>
      <div class="next">
        <button v-show="step === 0" class="btn btn-next" @click="step = 1">Continue &#8594;</button>
        <button v-show="step === 1" class="btn btn-back" @click="step = 0">Back</button>
        <button v-show="step === 1" class="btn btn-next" :disabled="submitting" @click="submit">Send</button>
      </div>
    </div>
    <div v-show="step === 2" class="column thanks">
      <div class="thanks-top">
        <img src="../assets/mail.svg" alt="Email" />
        <h2>Thanks! Chat soon</h2>
        <p>We've got your email. One of our sales agents will be contacting you soon.</p>
      </div>
      <!--<div class="thanks-bottom">
        <small>How was your experience using the Plan Your Project tool?<br />Give <a href="#">Feedback</a></small>
      </div>-->
    </div>
    <Error message="Sorry, there was an error submitting the form." :open="errorModalOpen" @close="errorModalOpen = false" />
  </div>
</template>

<script>
import Error from '../modals/Error.vue';

export default {
  name: 'ContactSales',
  data() {
    return {
      step: 0,
      firstName: '',
      lastName: '',
      email: '',
      country: '',
      message: '',
      optin: false,
      submitting: false,
      errorModalOpen: false,
    };
  },
  methods: {
    async submit() {
      this.submitting = true;
      const data = {
        fields: [
          { name: 'firstname', value: this.firstName },
          { name: 'lastname', value: this.lastName },
          { name: 'email', value: this.email },
          { name: 'country', value: this.country },
          { name: 'how_can_we_help_', value: this.message },
        ],
      };
      try {
        const res = await fetch('https://api.hsforms.com/submissions/v3/integration/submit/5121114/17201c43-4a28-44a9-8f80-83706789e564', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });
        if (res.ok) {
          this.step++;
        } else {
          this.errorModalOpen = true;
          this.submitting = false;
        }
      } catch {
        this.errorModalOpen = true;
        this.submitting = false;
      }
    },
  },
  components: {
    Error,
  },
};
</script>

<style scoped>
.input-wrapper {
  margin-bottom: 20px;
}

.input-wrapper label {
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
}

.input-wrapper input {
  padding: 15px;
  width: 100%;
}

.input-wrapper textarea {
  border: 1px solid #F2F2F2;
  border-radius: 8px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 14px;
  padding: 10px;
  resize: none;
  height: 150px;
  width: 100%;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 80px;
}

.next {
  padding-bottom: 10px;
  text-align: right;
}

.btn {
  text-transform: uppercase;
}

.btn-next {
  background-color: #3EAF29;
  border-radius: 25px;
  color: white;
  font-weight: bold;
  padding: 15px 40px;
}

.btn-back {
  font-weight: bold;
  margin-right: 30px;
}

.optin-message {
  font-size: 13px;
  line-height: 1.5;
}

.thanks {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.thanks-top, .thanks-bottom {
  margin: 0 auto;
  width: 75%;
}

.thanks-top {
  font-size: 14px;
  line-height: 1.5;
  margin-top: 50px;
}

.thanks-bottom {
  font-size: 12px;
  line-height: 1.5;
  margin-top: 100px;
}

.thanks-bottom a {
  font-weight: bold;
  text-decoration: underline;
  text-transform: uppercase;
}
</style>
