import lightpanelspower from '../assets/modals/NL22/lightpanelspower.jpg';

import canvaspower1 from '../assets/modals/NL29/canvaspower1.jpg';
import canvaspower2 from '../assets/modals/NL29/canvaspower2.jpg';
import canvaspower3 from '../assets/modals/NL29/canvaspower3.jpg';

import shapespower1 from '../assets/modals/NL42/shapespower1.jpg';
import shapespower2 from '../assets/modals/NL42/shapespower2.jpg';
import shapespower3 from '../assets/modals/NL42/shapespower3.jpg';

export default {
  NL22: [
    {
      title: 'Power Supply',
      description: 'Replacement power supply units for Nanoleaf Light Panels. Available in 60W model, supporting up to 30 panels. Includes controller unit. Learn more about the Nanoleaf Light Panels at <a href="https://www.nanoleaf.me/en-US/products/nanoleaf-light-panels" target="_blank">nanoleaf.me/rhythm</a>. Only compatible with the Nanoleaf Light Panels product line.',
      images: [lightpanelspower],
    },
  ],
  NL29: [
    {
      title: 'Power Supply',
      description: 'Replacement or additional power supply units for all Nanoleaf Canvas products. Available in 25W | Plug-In, 75W | Plug-In, and 75W | In-Wall models. For optimal performance, do not use both 25W and 75W power supplies in the same Nanoleaf Canvas system. In-Wall models connect discretely to building electrical wiring, and must be installed by a certified electrian only. Learn more about the Nanoleaf Canvas at <a href="https://www.nanoleaf.me/en-US/products/nanoleaf-canvas" target="_blank">nanoleaf.me/canvas</a>. Only compatible with the Nanoleaf Canvas product line.',
      images: [canvaspower1, canvaspower2, canvaspower3],
    },
  ],
  NL42: [
    {
      title: 'Power Supply',
      description: 'Replacement or additional power supply units for all Nanoleaf Shapes products. Available in 42W | Plug-In, 75W | Plug-In, and 75W | In-Wall models. For optimal performance, do not use both 42W and 75W power supplies in the same Nanoleaf Shapes system. In-Wall models connect discretely to building electrical wiring, and must be installed by a certified electrian only. Learn more at <a href="https://www.nanoleaf.me/en-US/products/nanoleaf-shapes" target="_blank">nanoleaf.me/shapes</a>. Only compatible with the Nanoleaf Shapes product line.',
      images: [shapespower1, shapespower2, shapespower3],
    },
  ],
};
