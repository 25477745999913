<template>
  <div class="container-left" ref="leftNav">
    <header>
      {{ steps[activeStep].title }} {{ stepOutOf }}
    </header>
    <main>
      <button v-show="activeStep === 0" :class="'btn btn-nav' + (modelOpen[model.model] ? ' active' : '')"
        v-for="(model, $index) in models" :key="'btn' + $index" @click="toggleModel(model.model)">
        <img v-show="modelOpen[model.model]" :src="model.image" :alt="model.title" />
        <img v-show="!modelOpen[model.model]" :src="model.image" :alt="model.title" />
        {{ model.title }}
      </button>
      <button v-show="activeStep === 1" :class="'btn btn-nav' + (powerOpen ? ' active' : '')" @click="powerOpen = !powerOpen">
        <img v-show="powerOpen" src="../assets/powerdark.svg" alt="Power" />
        <img v-show="!powerOpen" src="../assets/powerdark.svg" alt="Power" />
        Power
      </button>
      <button v-show="activeStep === 2" :class="'btn btn-nav' + (mountingOpen ? ' active' : '')" @click="mountingOpen = !mountingOpen">
        <img v-show="mountingOpen && activeModel === 'NL22'" src="../assets/lightpanelsmountdark.svg" alt="Mounting Plates" />
        <img v-show="!mountingOpen && activeModel === 'NL22'" src="../assets/lightpanelsmountdark.svg" alt="Mounting Plates" />
        <img v-show="mountingOpen && activeModel === 'NL29'" src="../assets/canvasmountdark.svg" alt="Mounting Plates" />
        <img v-show="!mountingOpen && activeModel === 'NL29'" src="../assets/canvasmountdark.svg" alt="Mounting Plates" />
        <img v-show="mountingOpen && activeModel === 'NL42'" src="../assets/shapesmountdark.svg" alt="Mounting Plates" />
        <img v-show="!mountingOpen && activeModel === 'NL42'" src="../assets/shapesmountdark.svg" alt="Mounting Plates" />
        Mounting<br />Plates
      </button>
      <div style="flex: 1;"></div>
      <button class="btn btn-nav ga-back-to-website"><a href="https://nanoleaf.me">Back to Nanoleaf.me</a></button>
    </main>
    <div v-for="(model, $index) in models" v-show="modelOpen[model.model]" class="model" :key="'model' + $index">
      <button class="btn btn-close" @click="closeNav"><img src="../assets/closeLeft.svg" alt="Close" /></button>
      <div v-if="model.children.length === 0" class="box" style="margin-bottom: 90px;">
        <header>
          <div class="title">
            {{ model.title }}
            <button class="btn" @click="toggleModal('learnMore', model.model)">
              <img src="../assets/info.svg" alt="Info" class="info ga-info" />
            </button>
          </div>
          <button :class="'btn btn-select' + (activeId === $index ? ' selected' : '')" @click="setActiveId($index)"></button>
        </header>
        <div v-if="activeId === $index && activeId <= 1">
          <PanelCount :name="model.title" :count="model.count" @reset="resetCount($index)" />
          <AddPanels :model-name="model.title" :b2b="isB2B" :smarter-kit-size="model.smarterKitSize" :smarter-kit-selected="model.smarterKitSelected"
            :smarter-kit-disabled="model.smarterKitDisabled" :expansion-pack-selected="model.expansionPacks.some((pack) => pack.count > 0)"
            :expansion-packs="model.expansionPacks" :count="model.count" @change="changeCount({ id: $index, ...$event })" />
        </div>
      </div>
      <div v-for="(shape, $index) in model.children" :key="'shape' + $index" class="box">
        <header>
          <div class="title">
            {{ shape.title }}
            <button class="btn" @click="toggleModal('learnMore', model.model)">
              <img src="../assets/info.svg" alt="Info" class="info ga-info" />
            </button>
          </div>
          <button :class="'btn btn-select' + (activeId === shape.id || shape.count > 0 ? ' selected' : '')" @click="setActiveId(shape.id)"></button>
        </header>
        <PanelCount v-if="activeId === shape.id || shape.count > 0" :name="shape.title" :count="shape.count" @reset="resetCount(shape.id)" />
        <AddPanels v-if="activeId === shape.id || shape.count > 0" :smarter-kit-size="shape.smarterKitSize" :smarter-kit-selected="shape.smarterKitSelected"
          :selected-shapes-smarter-kit="selectedShapesSmarterKit" :model-name="shape.title" :b2b="isB2B"
          :smarter-kit-disabled="shape.smarterKitDisabled" :expansion-pack-selected="shape.expansionPacks.some((pack) => pack.count > 0)"
          :expansion-packs="shape.expansionPacks" :count="shape.count" @change="changeCount({ id: shape.id, ...$event })" />
      </div>
      <div v-show="modelOpen['NL42']" class="shapes-message" style="margin-bottom: 70px;">
        <div>
          <img src="../assets/note.svg" alt="Note" />
          <span>
            All Nanoleaf Shapes can be linked together.<br />
            <a href="https://nanoleaf.me/en-CA/products/nanoleaf-shapes" target="_blank">Learn More</a>
          </span>
        </div>
      </div>
      <div style="flex: 1;"></div>
      <footer class="subtotal">
        Total Number of Panels
        <div v-show="modelOpen['NL22']" class="total">{{ numLightPanels }}</div>
        <div v-show="modelOpen['NL29']" class="total">{{ numCanvases }}</div>
        <div v-show="modelOpen['NL42']" class="total">{{ numShapes }}</div>
        <div v-show="modelOpen['NL59']" class="total">{{ numLinesTotal }}</div>
      </footer>
    </div>
    <LearnMore title="Nanoleaf Light Panels" :open="learnMore.NL22.open" :content="learnMore.NL22.content"
      @close="toggleModal('learnMore', 'NL22')" />
    <LearnMore title="Nanoleaf Canvas" :open="learnMore.NL29.open" :content="learnMore.NL29.content"
      @close="toggleModal('learnMore', 'NL29')" />
    <LearnMore title="Nanoleaf Shapes" :open="learnMore.NL42.open" :content="learnMore.NL42.content"
      @close="toggleModal('learnMore', 'NL42')" />
    <SwitchProduct :open="switchModalOpen" @ok="switchModel(modelToSwitch)" @cancel="switchModalOpen = false" />
    <div v-show="powerOpen" class="model power-supply">
      <button class="btn btn-close" @click="closeNav"><img src="../assets/closeLeft.svg" alt="Close" /></button>
      <div v-for="(model, $index) in models" :key="'power' + $index" v-show="activeModel === model.model">
        <div v-for="(powerSupply, $index) in model.powerSupplies" :key="'ps' + $index" v-show="!isB2B || powerSupply.b2b" class="box">
          <header>
            <div class="title">
              {{ powerSupply.title }}
              <button class="btn">
                <img src="../assets/info.svg" alt="Info" class="info ga-info" @click="toggleModal('powerModal', model.model)" />
              </button>
            </div>
            <button :class="'btn btn-select' + (powerSupply.selected ? ' selected' : '')"
              @click="selectPowerSupply({ model: model.model, index: $index })"></button>
          </header>
          <div class="description">
            {{ powerSupply.description }}
          </div>
          <PanelCount v-show="powerSupply.selected" name="Units" :count="powerSupply.count" :resettable="false" />
        </div>
      </div>
    </div>
    <LearnMore title="Nanoleaf Light Panels" :open="powerModal.NL22.open" :content="powerModal.NL22.content"
      @close="toggleModal('powerModal', 'NL22')" />
    <LearnMore title="Nanoleaf Canvas" :open="powerModal.NL29.open" :content="powerModal.NL29.content"
      @close="toggleModal('powerModal', 'NL29')" />
    <LearnMore title="Nanoleaf Shapes" :open="powerModal.NL42.open" :content="powerModal.NL42.content"
      @close="toggleModal('powerModal', 'NL42')" />
    <div v-show="mountingOpen" class="model power-supply">
      <button class="btn btn-close" @click="closeNav"><img src="../assets/closeLeft.svg" alt="Close" /></button>
      <div v-for="(model, $index) in models" :key="'mounting' + $index" v-show="activeModel === model.model">
        <div class="box">
          <header>
            <div class="title">
              Mounting Plate
              <button class="btn">
                <img src="../assets/info.svg" class="info" alt="Info ga-info" @click="toggleModal('mountingModal', model.model)" />
              </button>
            </div>
            <button :class="'btn btn-select' + (model.mountingPlatesSelected ? ' selected' : '')"
              @click="toggleMountingPlatesSelected"></button>
          </header>
          <div v-show="activeModel !== 'NL42'" class="description">
            Every Smarter Kit contains the right number of mounting tape. If you wish to have permanent installation, you will need mounting plates.
          </div>
          <div v-show="activeModel === 'NL42'" class="description">
            Every Kit contains the right number of mounting plates.
          </div>
          <PanelCount name="Plates" :count="totalMountingPlates" :resettable="false" />
          <div v-show="activeModel !== 'NL42'" class="add-mounting-plates">
            <AddMultiple v-for="(kit, $index) in model.mountingPlates" :key="'plates' + $index" :title="kit.title"
              subtitle="Pieces" :count="kit.count" :size="kit.size" @change="setMountingPlatesCount({ index: $index, count: $event })" />
          </div>
        </div>
      </div>
    </div>
    <LearnMore title="Nanoleaf Light Panels" :open="mountingModal.NL22.open" :content="mountingModal.NL22.content"
      @close="toggleModal('mountingModal', 'NL22')" />
    <LearnMore title="Nanoleaf Canvas" :open="mountingModal.NL29.open" :content="mountingModal.NL29.content"
      @close="toggleModal('mountingModal', 'NL29')" />
    <LearnMore title="Nanoleaf Shapes" :open="mountingModal.NL42.open" :content="mountingModal.NL42.content"
      @close="toggleModal('mountingModal', 'NL42')" />
    <TooManyLightPanels :open="tooManyLightPanelsModalOpen" @close="tooManyLightPanelsModalOpen = false" />
    <TooManyPanels :open="tooManyPanelsModalOpen" @close="tooManyPanelsModalOpen = false" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import PanelCount from './PanelCount.vue';
import AddPanels from './AddPanels.vue';
import LearnMore from '../modals/LearnMore.vue';
import TooManyLightPanels from '../modals/TooManyLightPanels.vue';
import TooManyPanels from '../modals/TooManyPanels.vue';
import SwitchProduct from '../modals/SwitchProduct.vue';
import AddMultiple from './AddMultiple.vue';
import learnMoreContent from '../modals/learnMoreContent.js';
import powerModalContent from '../modals/powerModalContent.js';
import mountingModalContent from '../modals/mountingModalContent.js';

export default {
  name: 'LeftNav',
  data() {
    return {
      initialModelOpened: false,
      modelOpen: {
        NL22: false,
        NL29: false,
        NL42: true,
      },
      learnMore: {
        NL22: {
          content: learnMoreContent.NL22,
          open: false,
        },
        NL29: {
          content: learnMoreContent.NL29,
          open: false,
        },
        NL42: {
          content: learnMoreContent.NL42,
          open: false,
        },
      },
      modelToSwitch: null,
      switchModalOpen: false,
      powerOpen: false,
      powerModal: {
        NL22: {
          content: powerModalContent.NL22,
          open: false,
        },
        NL29: {
          content: powerModalContent.NL29,
          open: false,
        },
        NL42: {
          content: powerModalContent.NL42,
          open: false,
        },
      },
      mountingOpen: false,
      mountingModal: {
        NL22: {
          content: mountingModalContent.NL22,
          open: false,
        },
        NL29: {
          content: mountingModalContent.NL29,
          open: false,
        },
        NL42: {
          content: mountingModalContent.NL42,
          open: false,
        },
      },
      tooManyLightPanelsModalOpen: false,
      tooManyPanelsModalOpen: false,
    };
  },
  mounted() {
    document.addEventListener('click', (e) => {
      const path = e.composedPath();
      if (!path.includes(this.$refs.leftNav) && !path[0].classList.contains('mask')) {
        this.closeNav();
      }
    });
  },
  watch: {
    // open correct model when loading from design code
    activeModel(model) {
      if (!this.initialModelOpened) {
        //set to false since toggleModel will toggle it
        this.$set(this.modelOpen, model, false);
        this.toggleModel(model);
        this.initialModelOpened = true;
      }
    },
    activeStep() {
      this.closeNav();
    },
  },
  computed: {
    ...mapGetters([
      'activeModel',
      'stepOutOf',
      'selectedShapesSmarterKit',
      'numLightPanels',
      'numCanvases',
      'numHexagons',
      'numTriangles',
      'numMiniTriangles',
      'numShapes',
      'numLines',
      'numLinesSquared',
      'numLinesTotal',
      'totalItemCount',
      'totalMountingPlates',
    ]),
    ...mapState([
      'activeId',
      'models',
      'activeStep',
      'steps',
      'isB2B',
      'measurementUnit',
      'wallWidth',
      'wallHeight',
    ]),
  },
  methods: {
    ...mapActions([
      'resetCount',
    ]),
    ...mapMutations([
      'setActiveId',
      'setCount',
      'selectPowerSupply',
      'toggleMountingPlatesSelected',
      'setMountingPlatesCount',
    ]),
    closeNav() {
      Object.keys(this.modelOpen).forEach((model) => this.$set(this.modelOpen, model, false));
      this.powerOpen = false;
      this.mountingOpen = false;
    },
    _toggleModel(model) {
      for (const m in this.modelOpen) {
        if (m !== model) {
          this.$set(this.modelOpen, m, false);
        }
      }
      this.$set(this.modelOpen, model, !this.modelOpen[model]);
    },
    switchModel(model) {
      for (let i = 0; i < 7; i++) {
        this.resetCount(i);
      }
      this._toggleModel(model);
      this.switchModalOpen = false;
    },
    toggleModel(model) {
      if (this.totalItemCount === 0 || model === this.activeModel) {
        this._toggleModel(model);
      } else {
        this.modelToSwitch = model;
        this.switchModalOpen = true;
      }
    },
    getShapeBoxClasses(index) {
      if (this.activeId === index + 2 || this.models[2].children[index].count > 0) {
        return 'box shape-box active';
      } else if (index === 2) {
        return 'box shape-box no-border';
      }
      return 'box shape-box';
    },
    toggleModal(modal, model) {
      this.$set(this[modal][model], 'open', !this[modal][model].open);
    },
    isEnoughSpace(numLightPanels, numCanvases, numHexagons, numTriangles, numMiniTriangles, numLines, numLinesSquared) {
      const wallWidth = this.wallWidth * (this.measurementUnit === 'metric' ? 1 : 2.54);
      const wallHeight = this.wallHeight * (this.measurementUnit === 'metric' ? 1 : 2.54);
      const wallAreaCm = wallWidth * wallHeight;

      const LP_AREA_CM = 24 * 21 * 0.67; // can fit 3 panels in 24 * 21 * 2 space
      const CANVAS_AREA_CM = 15 * 15;
      const HEXAGON_AREA_CM = 23 * 20 * 0.875;
      const TRIANGLE_AREA_CM = 23 * 20 * 0.67; // can fit 3 triangles in 23 * 20 * 2 space
      const MINI_TRIANGLE_AREA_CM = 11.5 * 10 * 0.67; // can fit 3 triangles in 11.5 * 10 * 2 space
      const LINES_AREA_CM = 27.85 * 2;

      const areaOfAllPanels = LP_AREA_CM * numLightPanels + CANVAS_AREA_CM * numCanvases + HEXAGON_AREA_CM * numHexagons + TRIANGLE_AREA_CM * numTriangles + MINI_TRIANGLE_AREA_CM * numMiniTriangles + LINES_AREA_CM * (numLines + numLinesSquared);
      return areaOfAllPanels <= wallAreaCm;
    },
    changeCount(data) {
      const { id, count } = data;
      const numLightPanels = id === 0 ? count : this.numLightPanels;
      const numCanvases = id === 1 ? count : this.numCanvases;
      const numHexagons = id === 2 ? count : this.numHexagons;
      const numTriangles = id === 3 ? count : this.numTriangles;
      const numMiniTriangles = id === 4 ? count : this.numMiniTriangles;
      const numLines = id === 5 ? count : this.numLines;
      const numLinesSquared = id === 6 ? count : this.numLinesSquared;

      if (numLightPanels > 30) {
        this.tooManyLightPanelsModalOpen = true;
      } else if (this.isEnoughSpace(numLightPanels, numCanvases, numHexagons, numTriangles, numMiniTriangles, numLines, numLinesSquared)) {
        this.setCount(data);
      } else {
        this.tooManyPanelsModalOpen = true;
      }
    },
  },
  components: {
    PanelCount,
    AddPanels,
    LearnMore,
    TooManyLightPanels,
    TooManyPanels,
    SwitchProduct,
    AddMultiple,
  },
};
</script>

<style scoped>
.model::-webkit-scrollbar {
  width: 5px;
}

.model::-webkit-scrollbar:hover {
  width: 10px;
}

.model::-webkit-scrollbar-track {
  background: transparent;
  margin-top: 4.75em;
  margin-bottom: 3.75em;
}

.model::-webkit-scrollbar-thumb {
  background-color: #DADEE1;
  border-radius: 20px;
}

.container-left {
  background-color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 65px);
  position: relative;
  width: 120px;
}

.btn.btn-nav:hover, .btn.btn-nav.active:hover {
  background-color: #F1F2F2;
}

.container-left > header {
  background-color: #3EAF29;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 8px;
  text-align: center;
}

main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
}

.model {
  background-color: white;
  border-left: 1px solid #FBFBFB;
  box-shadow: 5px 0 5px #CCC;
  box-sizing: border-box;
  position: absolute;
  left: 120px;
  overflow-y: scroll;
  padding-top: 20px;
  height: 100%;
  width: 350px;
  z-index: 3;
  padding-bottom: 7em;
}

.btn-close {
  position: fixed;
  left: 515px;
}

.model header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box {
  background-color: #F8F8F8;
  border-radius: 8px;
  margin: 15px 40px;
  padding: 10px 20px;
}

.btn.btn-select {
  top: 3px;
}

.title {
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
  max-width: 65%;
  align-items: baseline;
}

.title img {
  margin-left: 10px;
  width: 20px;
}

.btn-close img {
    width: 35px;
}

.shapes-message {
  padding: 10px 40px 0;
  opacity: 75%;
}

.shapes-message > div {
  display: flex;
  font-size: 12px;
  line-height: 1.5;
  padding-top: 10px;
}

.shapes-message img {
  margin-right: 15px;
  width: 25px;
}

.shapes-message a {
  padding-bottom: 2px;
  border-bottom: 1px solid #3A4C55;
  text-transform: uppercase;
}

.subtotal {
  background-color: white;
  border-top: 1px solid #f8f8f8;
  font-size: 16px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 303px;
  z-index: 2;
}

.subtotal .total {
  font-size: 24px;
  font-weight: bold;
  margin-right: 10px;
}

.power-supply h2 {
  padding-left: 30px;
}

.power-supply .description {
  font-size: 12px;
  line-height: 1.5;
  margin-top: 10px;
}

.power-supply .btn.btn-select {
  top: -8px;
}

.add-mounting-plates {
  background-color: white;
  border-radius: 5px;
  padding: 10px 0;
  margin-bottom: 10px;
}

@media only screen and (max-device-width: 1024px) and (orientation:landscape) {
  .box {
    border-radius: 5px;
    padding-bottom: 1em;
    padding-top: 1em;
  }

  .subtotal {
    margin-bottom: 0;
    padding: 12px 70px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation:landscape) {
  .subtotal {
    padding: 14px 70px 25px;
    width: 302px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .container-left {
    border: none;
    box-shadow: none;
    width: 90px;
  }

  .btn.btn-nav {
    font-size: 12px;
    height: 80px;
    padding: 17px 0;
  }

  .container-left button:nth-child(5) {
    height: 90px;
  }

  .model {
    -webkit-box-shadow: 5px 0px 15px -5px rgba(0,0,0,0.1);
    border-right: 1px solid #F1F2F2;
    box-shadow: 5px 0px 15px -5px rgba(0,0,0,0.1);
    left: 90px;
    width: 445px;
  }

  .box {
    border-radius: 5px;
    padding: 20px 30px;
  }

  .title {
    max-width: 80%;
  }

  .title img {
    position: relative;
    top: 4px;
    opacity: 50%;
  }

  .wrapper {
    background-color: #F1F2F2;
    padding: 10px 20px;
  }

  .total-number {
    margin-right: 12px;
  }

  .shapes-message > div {
    font-size: 12px;
    line-height: 150%;
  }

  .shapes-message a {
    border-bottom: 1px solid #3A4C55;
    font-weight: normal;
    padding-bottom: 2px;
    text-decoration: none;
  }

  .btn-close {
    left: 515px;
  }

  .btn-total-reset img {
    margin-left: 5px;
  }

  .expansion-packs-wrapper {
    background-color: white;
    border-top: none;
    margin-top: -15px;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .power-supply .box .title {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    margin-top: 11px;
  }

  .power-supply .description {
    margin-bottom: 20px;
    width: 100%;
  }
}

@media only screen and (min-device-width: 1367px) {
  .container-left {
    width: 100px;
  }

  .wrapper {
    background-color: #F1F2F2;
    padding: 10px 20px;
  }

  .btn.btn-nav {
    height: 90px;
  }

  .container-left button:nth-child(5) {
    height: 105px;
  }

  .box {
    border-radius: 5px;
    padding: 20px 30px;
  }

  .btn {
    padding: 20px 0;
  }

  .model {
    border-right: 1px solid #F1F2F2;
    -webkit-box-shadow: 5px 0px 15px -5px rgba(0,0,0,0.1);
    box-shadow: 5px 0px 15px -5px rgba(0,0,0,0.1);
    left: 100px;
    width: 445px;
  }

  .title {
    max-width: 75%;
  }

  .subtotal {
    width: 283px;
    padding: 14px 80px;
  }

  .btn-close {
    top: 75px;
    left: 525px;
  }

  .shapes-message {
    padding: 0 40px;
  }

  .title {
    max-width: 80%;
  }
}
</style>
