<template>
  <transition name="fade">
    <div v-show="open" :class="containerClasses" ref="container">
      <img v-show="showAlert" src="../assets/alertwhite.svg" alt="Alert" class="alert" />
      {{ text }}
      <button class="btn btn-close" @click="$emit('close')"><img src="../assets/closewhite.svg" alt="Close" /></button>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'WallTooltip',
  props: {
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default() {
        return 'default';
      },
      validator(value) {
        return ['default', 'error'].includes(value);
      },
    },
    open: {
      type: Boolean,
      required: true,
    },
    showAlert: {
      type: Boolean,
      default() {
        return false;
      },
    },
    timeout: {
      type: Number,
      default() {
        return 3000;
      },
      validator(value) {
        return value >= 0;
      },
    }
  },
  watch: {
    open(open) {
      if (open) {
        const { scrollTop } = document.getElementsByClassName('wall-wrapper')[0];
        const { top } = document.getElementById('wall').getBoundingClientRect();
        this.$refs.container.style.top = `${scrollTop + top + 20}px`;
        if (this.timeout > 0) {
          setTimeout(() => this.$emit('close'), this.timeout);
        }
      }
    },
  },
  computed: {
    containerClasses() {
      return `container ${this.type}`;
    },
  },
};
</script>

<style scoped>
.fade-enter-active {
  transition: all .5s;
}

.fade-leave-active {
  transition: all .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.container {
  border-radius: 5px;
  color: white;
  font-size: 14px;
  padding: 7px 15px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.default {
  background-color: #3EAF29;
}

.error {
  background-color: red;
}

.alert {
  width: 15px;
}

.btn-close {
  margin-left: 10px;
  padding: 0;
}

.btn-close img {
  width: 10px;
}
</style>
